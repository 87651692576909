import React  from "react";
import { Link, useNavigate } from "react-router-dom";
// import FeatherIcon from "feather-icons-react";
import { login02, loginicon01, loginicon02, loginicon03, loginlogo, logo } from "../../imagepath";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState } from "react";
import {observer} from 'mobx-react-lite';
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import { useStore } from "../../../store/root-store";
import { Button, Form, Input, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { SubmitButton } from "../../../common-components/submitButton";

// import ReactPasswordToggleIcon from 'react-password-toggle-icon';



const Login = observer(() => {
  const {login, loggingIn} = useStore('');
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  // let inputRef = useRef();
  // const showIcon = () => <i className="feather feather-eye" aria-hidden="true">
  //   <FeatherIcon icon="eye" />
  // </i>;
  // const hideIcon = () => <i className="feather feather-eye-slash" aria-hidden="true">
  //   <FeatherIcon icon="eye-off" />
  // </i>
  return (
    <>

      {/* Main Wrapper */}
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img
                    className="img-fluid"
                    src={login02}
                    alt="#"
                  />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/admin-dashboard" style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
                          <img src={logo} alt="#" style={{height: '39px'}}/><b style={{fontSize: '26px'}}>Medix lab</b>
                        </Link>
                      </div>
                      <h2>Login</h2>
                      {/* Form */}
                      <Form onFinish={async (values) => {
                          const res = await login(values);
                          if (res?.status_code == 200) {
                            // notification.success({message: res?.message})
                            navigate('/patientslist')
                          } else {
                            notification.error({
                              message: 'Login Failed',
                              description: 'We encountered an issue while trying to log you in. Please check your credentials and try again.',
                            });
                          }
                        }}>
                            <label>
                              Email <span className="login-danger">*</span>
                            </label>
                        <Form.Item name={'username'} rules={[{required: true, message: 'This field is required'}, {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          }]}>
                            <Input size="large" />
                        </Form.Item>
                            <label>
                              Password <span className="login-danger">*</span>
                            </label>
                        <Form.Item name={'password'} rules={[{required: true, message: 'This field is required'}]}>
                            <Input.Password
                            size="large"
                          />
                            </Form.Item>
                        <div className="forgotpass">
                          <div className="remember-me">
                            <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                              {" "}
                              Remember me
                              <input type="checkbox" name="radio" />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <Link to="/forgotpassword">Forgot Password?</Link>
                        </div>
                        <div className="form-group login-btn">
                          <SubmitButton loading={loggingIn} classnames={'btn btn-primary btn-block'} buttonText={'Login'}/>
                        </div>
                      </Form>
                      {/* /Form */}
                      <div className="next-sign">
                        <p className="account-subtitle">
                          Need an account? <Link to="/signup">Sign Up</Link>
                        </p>
                        {/* Social Login */}
                        <div className="social-login">
                          <Link to="#">
                            <img src={loginicon01} alt="#"/>
                          </Link>
                          <Link to="#">
                            <img src={loginicon02}alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={loginicon03}  alt="#"/>
                          </Link>
                        </div>
                        {/* /Social Login */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
      </div>
    </>
  );
});

export default Login;
