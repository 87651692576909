/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Form, notification, pagination, Table } from "antd";
import { onShowSizeChange, itemRender } from '../Pagination'
import Header from '../Header';
import Sidebar from '../Sidebar';
import {
  blogimg10, imagesend, pdficon, pdficon3, pdficon4, plusicon, refreshicon, searchnormal, blogimg12,
  blogimg2, blogimg4, blogimg6, blogimg8
} from '../imagepath';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { useStore } from '../../store/root-store';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { DeleteForever, Edit } from '@mui/icons-material';
import { SubmitButton } from '../../common-components/submitButton';
import PhoneInput from 'react-phone-number-input';
import { PageLayout } from '../../common-components/pageLayout';

const DoctorList = observer(() => {
  const { doctorsStore: { getDoctorsList, fetchDoctorsList, deleteDoctor, updatingRecord, createDoctorRecord, updateDoctorRecord } } = useStore('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);
  const [selectedEditID, setSelectedEditID] = useState(null);
  const [editform] = Form.useForm();
  const [createform] = Form.useForm();

  useEffect(() => {
    fetchDoctorsList();
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onChange = (date, dateString) => {
    // console.log(date, dateString);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <>
          <h2 className="profile-image">
            <Link to="#">{record?.name}</Link>
          </h2>

        </>
      ),
      sorter: (a, b) => a.name?.length - b.name?.length
    },
    {
      title: "Specialization",
      dataIndex: "specialty",
      sorter: (a, b) => a.specialty.length - b.specialty.length
    },
    {
      title: "Hospital name",
      dataIndex: "hospital_name",
      sorter: (a, b) => a.hospital_name?.length - b.hospital_name?.length
    },
    {
      title: "Mobile",
      dataIndex: "contact_no",
      sorter: (a, b) => a.contact_no?.length - b.contact_no?.length,
      render: (text, record) => (
        <>
          <Link to="#">{record.contact_no}</Link>
        </>
      )
    }, {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email?.length - b.email?.length
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address?.length - b.address?.length
    },
    {
      title: "JoiningDate",
      dataIndex: "created_at",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (text, record) => (
        <>{record.created_at ? moment(record.created_at)?.format('DD MMM YYYY') : ''}</>
      )
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (text, record) => (
        <>
          <div className="text-end">
            <span style={{cursor: 'pointer'}} className="me-3" onClick={async () => {
              if (record) {
                setSelectedEditID(record?.id);
                editform.setFieldsValue({
                  "name": record?.name,
                  "specialty": record?.specialty,
                  "hospital_name": record?.hospital_name,
                  "address": record?.address,
                  "contact_no": record?.contact_no,
                  "email": record?.email
                })
              }
            }} data-bs-toggle="modal" data-bs-target="#edit_doctor">
              <Edit style={{color: '#2e37a4'}}/>
            </span>
            <span style={{cursor: 'pointer'}} onClick={() => setSelectedDeleteId(record?.id)} data-bs-toggle="modal" data-bs-target="#delete_doctor">
              <DeleteForever style={{color: '#ff0000'}}/>
            </span>
          </div>
        </>
      ),
    },
  ]


  return (
    <>
      <Header />
      <Sidebar id='menu-item21' id1='menu-items21' activeClassName='doctors-list' />
      <PageLayout>
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Doctors </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Doctors List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk" style={{justifyContent: 'space-between'}}>
                          <h3>Doctors List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              {/* <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                />
                                <Link className="btn">
                                  <img
                                    src={searchnormal}
                                    alt="#"
                                  />
                                </Link>
                              </form> */}
                            </div>
                            <div className="add-group">
                              <span
                                className="btn btn-primary add-pluss ms-2"
                                style={{width: 'fit-content'}}
                                data-bs-toggle="modal" data-bs-target="#create_doctor"
                              >
                                <img src={plusicon} alt="#" />&nbsp;<span style={{fontWeight: '600'}}>Doctor</span>
                              </span>
                              {/* <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2">
                        </Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div> */}
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: getDoctorsList?.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={getDoctorsList}

                      // rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageLayout>
      <div id="delete_doctor" className="modal fade delete-modal" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={imagesend} alt="#" width={50} height={46} />
              <h3>Are you sure want to delete this ?</h3>
              <div className="m-t-20">
                {" "}
                <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                  Close
                </Link>
                <button type="submit" className="btn btn-danger" data-bs-dismiss="modal" onClick={async () => {
                  await deleteDoctor(selectedDeleteId);
                  fetchDoctorsList();
                }}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="edit_doctor" className="modal fade delete-modal" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
            <Form form={editform} onFinish={async (values) => {
                      const dataObj = {
                        "name": values?.name,
                        "specialty": values?.specialty,
                        "hospital_name": values?.hospital_name,
                        "address": values?.address,
                        "contact_no": values?.contact_no,
                        "email": values?.email
                      };
                      const res = await updateDoctorRecord(selectedEditID, dataObj);
                      if (res?.status_code == 200) {
                        fetchDoctorsList();
                        notification.success({ message: res?.message });
                        editform.resetFields();
                        setSelectedEditID(null);
                      } else {
                        notification.error({ message: 'Error occured!', description: JSON.stringify(res?.message) })
                      }
                    }}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Edit Doctor Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                                <label>
                                  Name <span className="login-danger">*</span>
                                </label>
                            <Form.Item name={'name'} rules={[{ required: true, message: 'This field is required' }]}>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                                <label>
                                  Specialty <span className="login-danger">*</span>
                                </label>
                            <Form.Item name={'specialty'} rules={[{ required: true, message: 'This field is required' }]}>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                                <label>
                                  Hospital Name <span className="login-danger">*</span>
                                </label>
                            <Form.Item name={'hospital_name'} rules={[{ required: true, message: 'This field is required' }]}>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                                <label>
                                  Address <span className="login-danger">*</span>
                                </label>
                            <Form.Item name={'address'} rules={[{ required: true, message: 'This field is required' }]}>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Contact Number <span className="login-danger">*</span>
                                </label>
                                <Form.Item name={'contact_no'} rules={[{ required: true, message: 'This field is required' }, {max: 13, message: 'Maximum 10 digits allowed!'}, {min: 13, message: 'Minimum 10 digits allowed!'}]}>
                                  <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="PK"
                                    countries={['PK']}
                                    className="form-control disable-input-border"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                                <label>
                                  Email <span className="login-danger">*</span>
                                </label>
                            <Form.Item name={'email'} rules={[{ required: true, message: 'This field is required' }]}>
                                <input
                                  className="form-control"
                                  type="email"
                                  placeholder=""
                                />
                            </Form.Item>
                          </div>
                        </div>
                        
                        <div className="m-t-20">
                          {" "}
                          <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                            Close
                          </Link>
                          <button type="submit" className="btn btn-danger" data-bs-dismiss="modal">
                            Update
                          </button>
                        </div>
                      </div>
                    </Form>
            </div>
          </div>
        </div>
      </div>



      <div id="create_doctor" className="modal fade delete-modal" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
            <Form form={createform} onFinish={async (values) => {
                      const dataObj = {
                        "name": values?.name,
                        "specialty": values?.specialty,
                        "hospital_name": values?.hospital_name,
                        "address": values?.address,
                        "contact_no": values?.contact_no,
                        "email": values?.email
                      };
                      const res = await createDoctorRecord(dataObj);
                      if (res?.status_code == 200) {
                        fetchDoctorsList();
                        notification.success({ message: res?.message });
                        createform.resetFields();
                        setSelectedEditID(null);
                      } else {
                        notification.error({ message: 'Error occured!', description: JSON.stringify(res?.message) })
                      }
                    }}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>New Doctor Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                                <label>
                                  Name <span className="login-danger">*</span>
                                </label>
                            <Form.Item name={'name'} rules={[{ required: true, message: 'This field is required' }]}>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                                <label>
                                  Specialty <span className="login-danger">*</span>
                                </label>
                            <Form.Item name={'specialty'} rules={[{ required: true, message: 'This field is required' }]}>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                                <label>
                                  Hospital Name <span className="login-danger">*</span>
                                </label>
                            <Form.Item name={'hospital_name'} rules={[{ required: true, message: 'This field is required' }]}>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                                <label>
                                  Address <span className="login-danger">*</span>
                                </label>
                            <Form.Item name={'address'} rules={[{ required: true, message: 'This field is required' }]}>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Contact Number <span className="login-danger">*</span>
                                </label>
                                <Form.Item name={'contact_no'} rules={[{ required: true, message: 'This field is required' }, {max: 13, message: 'Maximum 10 digits allowed!'}, {min: 13, message: 'Minimum 10 digits allowed!'}]}>
                                  <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="PK"
                                    countries={['PK']}
                                    className="form-control disable-input-border"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                                <label>
                                  Email <span className="login-danger">*</span>
                                </label>
                            <Form.Item name={'email'} rules={[{ required: true, message: 'This field is required' }]}>
                                <input
                                  className="form-control"
                                  type="email"
                                  placeholder=""
                                />
                            </Form.Item>
                          </div>
                        </div>
                        
                        <div className="m-t-20">
                          {" "}
                          <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                            Close
                          </Link>
                          <button type="submit" className="btn btn-danger" data-bs-dismiss="modal">
                            Save
                          </button>
                        </div>
                      </div>
                    </Form>
            </div>
          </div>
        </div>
      </div>
    </>

  )
})

export default DoctorList;
