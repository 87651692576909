import React from 'react'
import Header from '../../Header'
import Sidebar from '../../Sidebar'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import { Link } from 'react-router-dom';
const BlankPage = () => {
  return (
    <>
    <Header/>
    <Sidebar id="menu-item17"
        id1="menu-items17"
        activeClassName="blank-page"/>
    <>
    <div className="page-wrapper">
  <div className="content">
    {/* Page Header */}
    <div className="page-header">
      <div className="row">
        <div className="col-sm-12">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
             <Link to="#">Dashboard </Link>
            </li>
            <li className="breadcrumb-item">
              <i className="feather-chevron-right">
                <FeatherIcon icon="chevron-right"/>
                </i>
            </li>
            <li className="breadcrumb-item active">Blank Page</li>
          </ul>
        </div>
      </div>
    </div>
    {/* /Page Header */}
  </div>
</div>

    </>
    </>
  )
}

export default BlankPage;
