import "./vendor/bootstrap-icons/bootstrap-icons.css";
import "./vendor/aos/aos.css";
import "./vendor/fontawesome-free/css/all.min.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './landingPage.css';
import './landingpage.js';
import CountUp from "react-countup";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const LandingPage = () => {
    useEffect(() => {
        document.querySelectorAll('.faq-item h3, .faq-item .faq-toggle').forEach((faqItem) => {
            faqItem.addEventListener('click', () => {
              faqItem.parentNode.classList.toggle('faq-active');
            });
          });

          let scrollTop = document.querySelector('.scroll-top');

            function toggleScrollTop() {
                if (scrollTop) {
                window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
                }
            }
            scrollTop?.addEventListener('click', (e) => {
                e.preventDefault();
                window.scrollTo({
                top: 0,
                behavior: 'smooth'
                });
            });

            window.addEventListener('load', toggleScrollTop);
            document.addEventListener('scroll', toggleScrollTop);
    }, []);
    return (
        <>
        <div className="index-page">
            <header id="header" className="header sticky-top" style={{height: 'auto', position: 'sticky'}}>

                <div className="topbar d-flex align-items-center">
                    <div className="container d-flex justify-content-center justify-content-md-between">
                        <div className="contact-info d-flex align-items-center" style={{paddingLeft: '0px'}}>
                            <i className="bi bi-envelope d-flex align-items-center"><a href="mailto:hello@medixlab.co">hello@medixlab.co</a></i>
                            <i className="bi bi-phone d-flex align-items-center ms-4"><span>+92 303 4142927</span></i>
                        </div>
                        <div className="social-links d-none d-md-flex align-items-center">
                            <a href="#" className="twitter"><i className="bi bi-twitter-x"></i></a>
                            <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
                            <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
                            <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
                        </div>
                    </div>
                </div>

                <div className="branding d-flex align-items-center">

                    <div className="container position-relative d-flex align-items-center justify-content-between">
                        <a href="index.html" className="logo d-flex align-items-center me-auto">
                            {/* <img src="/img/logo.png" alt="" /> */}
                            <h1 className="sitename">Medixlab</h1>
                        </a>

                        <nav id="navmenu" className="navmenu">
                            <ul>
                                <li><a href="#hero" className="active">Home<br /></a></li>
                                <li><a href="#about">About</a></li>
                                <li><a href="#services">Services</a></li>
                                <li><a href="#departments">Departments</a></li>
                                <li><a href="#doctors">Doctors</a></li>
                                {/* <li className="dropdown"><a href="#"><span>Dropdown</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                                    <ul>
                                        <li><a href="#">Dropdown 1</a></li>
                                        <li className="dropdown"><a href="#"><span>Deep Dropdown</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                                            <ul>
                                                <li><a href="#">Deep Dropdown 1</a></li>
                                                <li><a href="#">Deep Dropdown 2</a></li>
                                                <li><a href="#">Deep Dropdown 3</a></li>
                                                <li><a href="#">Deep Dropdown 4</a></li>
                                                <li><a href="#">Deep Dropdown 5</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="#">Dropdown 2</a></li>
                                        <li><a href="#">Dropdown 3</a></li>
                                        <li><a href="#">Dropdown 4</a></li>
                                    </ul>
                                </li> */}
                                <li><a href="#contact">Contact</a></li>
                                <li><a href="#appointment">Appointment</a></li>
                            </ul>
                            <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
                        </nav>

                        <Link className="cta-btn d-none d-sm-block" to="/login">Login</Link>

                    </div>

                </div>

            </header>

            <main className="main">

                <section id="hero" className="hero section light-background">

                    <img src="/img/hero-bg.jpg" alt="" data-aos="fade-in" />

                    <div className="container position-relative">

                        <div className="welcome position-relative" data-aos="fade-down" data-aos-delay="100">
                            <h2>WELCOME TO MEDIXLAB</h2>
                            <p>Revolutionizing Laboratory Management</p>
                        </div>

                        <div className="content row gy-4">
                            <div className="col-lg-4 d-flex align-items-stretch">
                                <div className="why-box" data-aos="zoom-out" data-aos-delay="200">
                                    <h3>Why Choose Medixlab?</h3>
                                    <p>
                                        At Medix lab, we understand the critical role that efficient management plays in the success of any laboratory. Whether you're running a research facility, a medical lab, our cutting-edge Lab Management System is designed to streamline your operations, enhance productivity, and ensure compliance with industry standards.
                                    </p>
                                    <div className="text-center">
                                        <a href="#about" className="more-btn"><span>Learn More</span> <i className="bi bi-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 d-flex align-items-stretch">
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="row gy-4">

                                        <div className="col-xl-4 d-flex align-items-stretch">
                                            <div className="icon-box" data-aos="zoom-out" data-aos-delay="300">
                                                <i className="bi bi-clipboard-data"></i>
                                                <h4>Expert Support</h4>
                                                <p>Our dedicated team is always here to help. From setup to ongoing support, we ensure a smooth experience.</p>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 d-flex align-items-stretch">
                                            <div className="icon-box" data-aos="zoom-out" data-aos-delay="400">
                                                <i className="bi bi-gem"></i>
                                                <h4>Scalable Solutions</h4>
                                                <p>As your lab grows, Medix lab grows with you. Our system is designed to scale, accommodating everything from small labs to large research facilities.</p>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 d-flex align-items-stretch">
                                            <div className="icon-box" data-aos="zoom-out" data-aos-delay="500">
                                                <i className="bi bi-inboxes"></i>
                                                <h4>Proven Reliability</h4>
                                                <p>Trusted by labs across industries, Medix lab delivers consistent performance and dependability you can count on.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section id="about" className="about section">

                    <div className="container">

                        <div className="row gy-4 gx-5">

                            <div className="col-lg-6 position-relative align-self-start" data-aos="fade-up" data-aos-delay="200">
                                <img src="/img/about.jpg" className="img-fluid" alt="" />
                            </div>

                            <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
                                <h3>About Us</h3>
                                <p>
                                    Medix lab delivers solutions that drive efficiency and precision. Our platform is built to empower labs to achieve their full potential, providing innovative tools and technologies.
                                </p>
                                <ul>
                                    <li>
                                        <i className="fa-solid fa-vial-circle-check"></i>
                                        <div>
                                            <h5>Committed to Excellence</h5>
                                            <p>We ensure every aspect of our system aligns with industry standards, enabling labs to operate smoothly while maintaining compliance.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-pump-medical"></i>
                                        <div>
                                            <h5>Innovative and Reliable Solutions</h5>
                                            <p>Medix lab offers user-friendly, scalable, and customizable tools designed to streamline workflows, enhance data security, and support lab growth.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="fa-solid fa-heart-circle-xmark"></i>
                                        <div>
                                            <h5>A Partner You Can Trust</h5>
                                            <p>With a focus on continuous improvement, Medix lab is committed to helping labs achieve success through reliable, cutting-edge solutions.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>

                </section>

                <section id="stats" className="stats section light-background">

                    <div className="container" data-aos="fade-up" data-aos-delay="100">

                        <div className="row gy-4">

                            <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
                                <i className="fa-solid fa-user-doctor"></i>
                                <div className="stats-item">
                                    <CountUp delay={0.4} end={28} duration={0.6} className="purecounter" />
                                    <p>Doctors</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
                                <i className="fa-regular fa-hospital"></i>
                                <div className="stats-item">
                                    <CountUp delay={0.4} end={84} duration={0.6} className="purecounter" />
                                    <p>Departments</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
                                <i className="fas fa-flask"></i>
                                <div className="stats-item">
                                    <CountUp delay={0.4} end={77} duration={0.6} className="purecounter" />                                    
                                    <p>Research Labs</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
                                <i className="fas fa-award"></i>
                                <div className="stats-item">
                                    <CountUp delay={0.4} end={66} duration={0.6} className="purecounter" />                                    
                                    <p>Awards</p>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>

                <section id="services" className="services section">

                    <div className="container section-title" data-aos="fade-up">
                        <h2>Services</h2>
                        <p>Our platform offers a complete suite of tools for managing every aspect of your lab, ensuring seamless operations and accurate data handling.</p>
                    </div>

                    <div className="container">

                        <div className="row gy-4">

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <i className="fas fa-heartbeat"></i>
                                    </div>
                                    <a href="#" className="stretched-link">
                                        <h3>Sample Tracking</h3>
                                    </a>
                                    <p>Track samples with precision from collection to analysis. Our system ensures that no sample is ever misplaced, providing full traceability and security.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="fas fa-pills"></i>
                                    </div>
                                    <a href="#" className="stretched-link">
                                        <h3>Inventory Control</h3>
                                    </a>
                                    <p>Maintain optimal stock levels with automated alerts and efficient inventory management, helping you avoid shortages and overstock situations.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="fas fa-hospital-user"></i>
                                    </div>
                                    <a href="#" className="stretched-link">
                                        <h3>Data Security & Compliance</h3>
                                    </a>
                                    <p>Medix lab prioritizes data protection, offering secure solutions that comply with industry regulations to safeguard sensitive information.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="fas fa-dna"></i>
                                    </div>
                                    <a href="#" className="stretched-link">
                                        <h3>Customizable Workflows</h3>
                                    </a>
                                    <p>Tailor our system to fit your lab's unique processes, allowing you to customize workflows, reporting, and notifications for maximum efficiency.</p>
                                    <a href="#" className="stretched-link"></a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="fas fa-wheelchair"></i>
                                    </div>
                                    <a href="#" className="stretched-link">
                                        <h3>Support & Training</h3>
                                    </a>
                                    <p>Our team provides expert support and training to ensure you get the most out of our platform, with ongoing assistance to meet your lab's needs.</p>
                                    <a href="#" className="stretched-link"></a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="fas fa-notes-medical"></i>
                                    </div>
                                    <a href="#" className="stretched-link">
                                        <h3>Scalable Solutions</h3>
                                    </a>
                                    <p>As your lab grows, our platform scales with you, offering flexible solutions that adapt to increasing demands and complex workflows.</p>
                                    <a href="#" className="stretched-link"></a>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>

                <section id="appointment" className="appointment section">

                    <div className="container section-title" data-aos="fade-up">
                        <h2>Appointment</h2>
                        <p>Schedule Your Consultation Today for Expert Lab Management Solutions.</p>
                    </div>

                    <div className="container" data-aos="fade-up" data-aos-delay="100">

                        <form action="" className="php-email-form">
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required="" />
                                </div>
                                <div className="col-md-4 form-group mt-3 mt-md-0">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required="" />
                                </div>
                                <div className="col-md-4 form-group mt-3 mt-md-0">
                                    <input type="tel" className="form-control" name="phone" id="phone" placeholder="Your Phone" required="" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 form-group mt-3">
                                    <input type="datetime-local" name="date" className="form-control datepicker" id="date" placeholder="Appointment Date" required="" />
                                </div>
                                <div className="col-md-4 form-group mt-3">
                                    <select name="department" id="department" className="form-select" required="">
                                        <option value="">Select Department</option>
                                        <option value="Department 1">Department 1</option>
                                        <option value="Department 2">Department 2</option>
                                        <option value="Department 3">Department 3</option>
                                    </select>
                                </div>
                                <div className="col-md-4 form-group mt-3">
                                    <select name="doctor" id="doctor" className="form-select" required="">
                                        <option value="">Select Doctor</option>
                                        <option value="Doctor 1">Doctor 1</option>
                                        <option value="Doctor 2">Doctor 2</option>
                                        <option value="Doctor 3">Doctor 3</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group mt-3">
                                <textarea className="form-control" name="message" rows="5" placeholder="Message (Optional)"></textarea>
                            </div>
                            <div className="mt-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your appointment request has been sent successfully. Thank you!</div>
                                <div className="text-center"><button type="submit">Make an Appointment</button></div>
                            </div>
                        </form>

                    </div>

                </section>

                <section id="departments" className="departments section">

                    <div className="container section-title" data-aos="fade-up">
                        <h2>Departments</h2>
                        <p>Comprehensive Lab Testing Across Specialized Departments for Accurate and Reliable Results.</p>
                    </div>
                    <div className="container" data-aos="fade-up" data-aos-delay="100">

                        <div className="row">
                            <div className="col-lg-3">
                                <ul className="nav nav-tabs flex-column">
                                    <li className="nav-item">
                                        <a style={{borderTop: 0, borderLeft: 0}} className="nav-link active show" data-bs-toggle="tab" href="#departments-tab-1">Biochemistry</a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{borderTop: 0, borderLeft: 0}} className="nav-link" data-bs-toggle="tab" href="#departments-tab-2">Hematology</a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{borderTop: 0, borderLeft: 0}} className="nav-link" data-bs-toggle="tab" href="#departments-tab-3">Microbiology</a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{borderTop: 0, borderLeft: 0}} className="nav-link" data-bs-toggle="tab" href="#departments-tab-4">Pathology</a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{borderTop: 0, borderLeft: 0}} className="nav-link" data-bs-toggle="tab" href="#departments-tab-5">Serology</a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{borderTop: 0, borderLeft: 0}} className="nav-link" data-bs-toggle="tab" href="#departments-tab-6">Toxicology</a>
                                    </li>
                                    <li className="nav-item">
                                        <a style={{borderTop: 0, borderLeft: 0}} className="nav-link" data-bs-toggle="tab" href="#departments-tab-7">Immunology</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-9 mt-4 mt-lg-0">
                                <div className="tab-content">
                                    <div className="tab-pane active show" id="departments-tab-1">
                                        <div className="row">
                                            <div className="col-lg-8 details order-2 order-lg-1">
                                                <h3>Biochemistry</h3>
                                                <p className="fst-italic">Analyzing blood and other body fluids to measure chemical substances and enzymes, vital for diagnosing metabolic conditions.</p>
                                                <p>Our Biochemistry department specializes in analyzing blood, urine, and other body fluids to measure various chemical substances and enzymes. This department plays a crucial role in diagnosing metabolic disorders, assessing organ function, and monitoring chronic diseases. Using advanced techniques, we provide detailed insights into your body's chemical processes to support accurate diagnosis and effective treatment plans.</p>
                                            </div>
                                            <div className="col-lg-4 text-center order-1 order-lg-2">
                                                <img src="/img/departments-1.jpg" alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="departments-tab-2">
                                        <div className="row">
                                            <div className="col-lg-8 details order-2 order-lg-1">
                                                <h3>Hematology</h3>
                                                <p className="fst-italic">Conducting blood tests to assess blood cells and coagulation, essential for diagnosing anemia, clotting disorders, and more.</p>
                                                <p>The Hematology department focuses on the study of blood and its components, including red blood cells, white blood cells, and platelets. We conduct comprehensive blood tests to diagnose conditions such as anemia, leukemia, and clotting disorders. Our expert team uses state-of-the-art technology to ensure precise and reliable results, aiding in the effective management and treatment of blood-related health issues.</p>
                                            </div>
                                            <div className="col-lg-4 text-center order-1 order-lg-2">
                                                <img src="/img/departments-2.jpg" alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="departments-tab-3">
                                        <div className="row">
                                            <div className="col-lg-8 details order-2 order-lg-1">
                                                <h3>Microbiology</h3>
                                                <p className="fst-italic">Identifying and studying microorganisms such as bacteria, viruses, and fungi to diagnose infections and guide treatment.</p>
                                                <p>In the Microbiology department, we specialize in identifying and analyzing microorganisms such as bacteria, viruses, fungi, and parasites. Our tests are essential for diagnosing infections, determining their causes, and guiding appropriate treatment. We employ advanced culturing and molecular techniques to ensure accurate identification and sensitivity testing, contributing to effective infection control and patient care.</p>
                                            </div>
                                            <div className="col-lg-4 text-center order-1 order-lg-2">
                                                <img src="/img/departments-3.jpg" alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="departments-tab-4">
                                        <div className="row">
                                            <div className="col-lg-8 details order-2 order-lg-1">
                                                <h3>Pathology</h3>
                                                <p className="fst-italic">Examining tissues, cells, and organs to diagnose diseases, including cancers, through histology and cytology.</p>
                                                <p>Our Pathology department examines tissues, cells, and organs to diagnose diseases through detailed histological and cytological analysis. Pathologists perform biopsies and autopsies to provide crucial insights into disease presence and progression. This department plays a vital role in diagnosing cancers, identifying disease patterns, and guiding treatment decisions based on detailed pathological findings.</p>
                                            </div>
                                            <div className="col-lg-4 text-center order-1 order-lg-2">
                                                <img src="/img/departments-4.jpg" alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="departments-tab-5">
                                        <div className="row">
                                            <div className="col-lg-8 details order-2 order-lg-1">
                                                <h3>Serology</h3>
                                                <p className="fst-italic">Testing blood serum to detect antibodies and antigens, crucial for diagnosing viral infections, autoimmune diseases, and blood group compatibility.</p>
                                                <p>The Serology department focuses on analyzing blood serum to detect antibodies and antigens. This department is crucial for diagnosing viral infections, autoimmune diseases, and determining blood group compatibility. By assessing the presence and levels of specific antibodies and antigens, we provide valuable information for disease diagnosis, monitoring, and vaccination status.</p>
                                            </div>
                                            <div className="col-lg-4 text-center order-1 order-lg-2">
                                                <img src="/img/departments-5.jpg" alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="departments-tab-6">
                                        <div className="row">
                                            <div className="col-lg-8 details order-2 order-lg-1">
                                                <h3>Toxicology</h3>
                                                <p className="fst-italic">Analyzing bodily fluids and tissues for toxins, drugs, and poisons, supporting medical, forensic, and environmental investigations.</p>
                                                <p>Our Toxicology department is dedicated to detecting and analyzing toxins, drugs, and poisons in bodily fluids and tissues. We perform tests to identify substance abuse, drug overdoses, and exposure to harmful chemicals. This department supports medical, forensic, and environmental investigations by providing accurate and timely results that aid in treatment and safety assessments.</p>
                                            </div>
                                            <div className="col-lg-4 text-center order-1 order-lg-2">
                                                <img src="/img/departments-6.jpg" alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="departments-tab-7">
                                        <div className="row">
                                            <div className="col-lg-8 details order-2 order-lg-1">
                                                <h3>Immunology</h3>
                                                <p className="fst-italic">Testing for immune system disorders, allergies, and autoimmune diseases, providing insights into immune function and health.</p>
                                                <p>Our Immunology department is dedicated to evaluating immune system function and diagnosing immune-related disorders. We conduct tests to detect autoimmune diseases, allergies, and immune deficiencies. By analyzing various immune markers, we provide critical information for diagnosing conditions such as rheumatoid arthritis, lupus, and allergies, helping to tailor treatments and improve patient outcomes.</p>
                                            </div>
                                            <div className="col-lg-4 text-center order-1 order-lg-2">
                                                <img src="/img/departments-7.jpg" alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>

                <section id="doctors" className="doctors section">

                    <div className="container section-title" data-aos="fade-up">
                        <h2>Doctors</h2>
                        <p>Meet Our Expert Doctors: Leading Specialists Committed to Your Health and Well-Being.</p>
                    </div>

                    <div className="container">

                        <div className="row gy-4">

                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="team-member d-flex align-items-start">
                                    <div className="pic"><img src="/img/doctors/doctors-1.jpg" className="img-fluid" alt="" /></div>
                                    <div className="member-info">
                                        <h4>Dr M. Asif Siddiqui</h4>
                                        <span>Child Specialist & Neonatologist</span>
                                        <p>MBBS, FCPS ( Pediatric Medicine) FCPS (Neonatology),
MME,
CHR, PGPN (USA), IPPN</p>
                                        <div className="social">
                                            <a href=""><i className="bi bi-twitter-x"></i></a>
                                            <a href=""><i className="bi bi-facebook"></i></a>
                                            <a href=""><i className="bi bi-instagram"></i></a>
                                            <a href=""> <i className="bi bi-linkedin"></i> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="team-member d-flex align-items-start">
                                    <div className="pic"><img src="/img/doctors/doctors-dummy-2.jpg" className="img-fluid" alt="" /></div>
                                    <div className="member-info">
                                        <h4>Dr Shazia Younas</h4>
                                        <span>Consultant Gynaecologist & Obstetrician</span>
                                        <p>Ex Assistant Professor WMC
                                        Gynecological Laparoscopic Surgeon</p>
                                        <div className="social">
                                            <a href=""><i className="bi bi-twitter-x"></i></a>
                                            <a href=""><i className="bi bi-facebook"></i></a>
                                            <a href=""><i className="bi bi-instagram"></i></a>
                                            <a href=""> <i className="bi bi-linkedin"></i> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                                <div className="team-member d-flex align-items-start">
                                    <div className="pic"><img src="/img/doctors/doctors-3.jpg" className="img-fluid" alt="" /></div>
                                    <div className="member-info">
                                        <h4>Dr Ahmed Zaka</h4>
                                        <span>Consultant Gastroenterologist</span>
                                        <p>MBBS. FCPS |
                                        Consultant Gastroenterologist | Health Bridge Hospital</p>
                                        <div className="social">
                                            <a href=""><i className="bi bi-twitter-x"></i></a>
                                            <a href=""><i className="bi bi-facebook"></i></a>
                                            <a href=""><i className="bi bi-instagram"></i></a>
                                            <a href=""> <i className="bi bi-linkedin"></i> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
                                <div className="team-member d-flex align-items-start">
                                    <div className="pic"><img src="/img/doctors/doctors-dummy-2.jpg" className="img-fluid" alt="" /></div>
                                    <div className="member-info">
                                        <h4>Dr Sumbal Afzal</h4>
                                        <span>Obstetrician & Gynaecologist</span>
                                        <p>MBBS, MCPS
OBSTETRICIAN & GYNECOLOGIST |
CONSULTANT GANGA RAM HOSPITA</p>
                                        <div className="social">
                                            <a href=""><i className="bi bi-twitter-x"></i></a>
                                            <a href=""><i className="bi bi-facebook"></i></a>
                                            <a href=""><i className="bi bi-instagram"></i></a>
                                            <a href=""> <i className="bi bi-linkedin"></i> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>

                <section id="faq" className="faq section light-background">

                    <div className="container section-title" data-aos="fade-up">
                        <h2>Frequently Asked Questions</h2>
                        <p>Find Answers to Your Questions: Our FAQs Provide Helpful Information and Guidance.</p>
                    </div>

                    <div className="container">

                        <div className="row justify-content-center">

                            <div className="col-lg-10" data-aos="fade-up" data-aos-delay="100">

                                <div className="faq-container">

                                    <div className="faq-item faq-active">
                                        <h3>What types of tests do you offer?</h3>
                                        <div className="faq-content">
                                            <p>We offer a wide range of tests across various departments, including biochemistry, hematology, microbiology, immunology, molecular diagnostics, pathology, serology, and toxicology. For a detailed list of tests, please visit our services page or contact us directly.</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                    <div className="faq-item">
                                        <h3>How can I schedule a test?</h3>
                                        <div className="faq-content">
                                            <p>You can schedule a test by contacting us via phone, email, or through our online appointment system. Our team will assist you in setting up an appointment at a convenient time.</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                    <div className="faq-item">
                                        <h3>Do I need a referral to get a test?</h3>
                                        <div className="faq-content">
                                            <p>In most cases, a referral from your doctor is required for specific tests. However, some tests may be available without a referral. Please check with us or your healthcare provider for specific requirements.</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                    <div className="faq-item">
                                        <h3>How do I prepare for a test?</h3>
                                        <div className="faq-content">
                                            <p>Preparation varies depending on the type of test. For detailed instructions on how to prepare, please refer to the specific test information provided by our team or consult with your healthcare provider.</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>
                                    <div className="faq-item">
                                        <h3>When will I receive my test results?</h3>
                                        <div className="faq-content">
                                            <p>Results are typically available within a few days to a week, depending on the type of test. We will notify you when your results are ready and provide them through your preferred method, whether it's online, by mail, or in person.</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                    <div className="faq-item">
                                        <h3>Are your lab tests covered by insurance?</h3>
                                        <div className="faq-content">
                                            <p>Coverage varies based on your insurance plan. We recommend checking with your insurance provider to confirm coverage and any potential out-of-pocket costs for lab tests.</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                    <div className="faq-item">
                                        <h3>How do you ensure the accuracy of your tests?</h3>
                                        <div className="faq-content">
                                            <p>We adhere to strict quality control and assurance practices, using state-of-the-art equipment and following industry standards to ensure the accuracy and reliability of all our tests.</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </section>

                <section id="testimonials" className="testimonials section">

                    <div className="container">

                        <div className="row align-items-center">

                            <div className="col-lg-5 info" data-aos="fade-up" data-aos-delay="100">
                                <h3>Testimonials</h3>
                                <p>
                                    Our experience with Medix lab has been exceptional. The quality of their services and the accuracy of their tests have greatly exceeded our expectations. Their support team is incredibly responsive, and the technology is both innovative and reliable. We’ve seen a significant improvement in our lab’s efficiency and data accuracy since partnering with them. Highly recommended for anyone looking for top-notch lab management solutions.
                                </p>
                            </div>

                            <div className="col-lg-7" data-aos="fade-up" data-aos-delay="200">

                                <div className="swiper init-swiper">
                                    
                                    <div className="swiper-wrapper">

                                        <div className="swiper-slide">
                                            <div className="testimonial-item">
                                                <div className="d-flex">
                                                    <img src="/img/doctors/doctors-dummy-1.png" className="testimonial-img flex-shrink-0" alt="" />
                                                    <div>
                                                        <h3>Rafaqat</h3>
                                                        <h4>Ceo &amp; Founder</h4>
                                                        <div className="stars">
                                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    <span>Medix lab has significantly improved our lab operations with their innovative solutions and exceptional support. Their technology is top-notch and has enhanced our efficiency and accuracy. Highly recommended for anyone looking for reliable and advanced lab management.</span>
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="swiper-slide">
                                            <div className="testimonial-item">
                                                <div className="d-flex">
                                                    <img src="/img/doctors/doctors-dummy-2.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                                    <div>
                                                        <h3>Fatima</h3>
                                                        <h4>Designer</h4>
                                                        <div className="stars">
                                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    <span>Medix lab has revolutionized how we manage our lab operations. Their technology is cutting-edge and their support is unmatched. We’ve experienced a noticeable boost in efficiency and accuracy, and their responsive team is always there to help. Highly recommended!</span>
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="swiper-slide">
                                            <div className="testimonial-item">
                                                <div className="d-flex">
                                                    <img src="/img/doctors/doctors-dummy-2.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                                    <div>
                                                        <h3>Mariam</h3>
                                                        <h4>Store Owner</h4>
                                                        <div className="stars">
                                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    <span>Working with Medix lab has been a game-changer for us. Their reliable testing solutions and outstanding customer service have streamlined our processes and improved our overall lab management. We couldn’t be happier with the results.</span>
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="swiper-slide">
                                            <div className="testimonial-item">
                                                <div className="d-flex">
                                                    <img src="/img/doctors/doctors-dummy-1.png" className="testimonial-img flex-shrink-0" alt="" />
                                                    <div>
                                                        <h3>Bilal</h3>
                                                        <h4>Freelancer</h4>
                                                        <div className="stars">
                                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    <span>The precision and reliability of Medix lab’ services have exceeded my expectations. Their innovative solutions have greatly enhanced the efficiency of my projects, and their customer support is always ready to assist with any issues. A top choice for lab management.</span>
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="swiper-slide">
                                            <div className="testimonial-item">
                                                <div className="d-flex">
                                                    <img src="/img/doctors/doctors-dummy-1.png" className="testimonial-img flex-shrink-0" alt="" />
                                                    <div>
                                                        <h3>Rizwan</h3>
                                                        <h4>Entrepreneur</h4>
                                                        <div className="stars">
                                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    <span>Medix lab provides outstanding service and innovative solutions that have significantly improved our lab's efficiency. Their attention to detail and commitment to quality have been impressive. I’m grateful for their support and highly recommend their services to others.</span>
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="swiper-pagination"></div>
                                </div>

                            </div>

                        </div>

                    </div>

                </section>
                <section id="gallery" className="gallery section">

                    <div className="container section-title" data-aos="fade-up">
                        <h2>Gallery</h2>
                        <p>Explore Our Gallery: Showcasing the Cutting-Edge Facilities and Innovative Solutions of Medix lab.</p>
                    </div>

                    <div className="container-fluid" data-aos="fade-up" data-aos-delay="100">

                        <div className="row g-0">

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                        <img src="/img/departments-1.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                        <img src="/img/gallery/gallery-2.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                        <img src="/img/gallery/gallery-3.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                        <img src="/img/departments-4.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                        <img src="/img/gallery/gallery-5.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                        <img src="/img/gallery/gallery-6.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                        <img src="/img/gallery/gallery-7.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                        <img src="/img/departments-7.jpg" alt="" className="img-fluid" />
                                </div>
                            </div>

                        </div>

                    </div>

                </section>

                <section id="contact" className="contact section">

                    <div className="container section-title" data-aos="fade-up">
                        <h2>Contact</h2>
                        <p>Get in Touch: Reach Out to Us for Inquiries, Support, or More Information.</p>
                    </div>

                    <div className="mb-5" data-aos="fade-up" data-aos-delay="200">
                        <iframe style={{border:0, width: '100%', height: '270px'}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48389.78314118045!2d-74.006138!3d40.710059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a22a3bda30d%3A0xb89d1fe6bc499443!2sDowntown%20Conference%20Center!5e0!3m2!1sen!2sus!4v1676961268712!5m2!1sen!2sus" frameborder="0" allowfullscreen="" loading="lazy"></iframe>
                    </div>

                    <div className="container" data-aos="fade-up" data-aos-delay="100">

                        <div className="row gy-4">

                            <div className="col-lg-4">
                                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                                    <i className="bi bi-geo-alt flex-shrink-0"></i>
                                    <div>
                                        <h3>Location</h3>
                                        <p>34-H Phase 1 DHA, DHA, Lahore, 54000</p>
                                    </div>
                                </div>

                                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                                    <i className="bi bi-telephone flex-shrink-0"></i>
                                    <div>
                                        <h3>Call Us</h3>
                                        <p>+92 303 4142927</p>
                                    </div>
                                </div>

                                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="500">
                                    <i className="bi bi-envelope flex-shrink-0"></i>
                                    <div>
                                        <h3>Email Us</h3>
                                        <p>hello@medixlab.co</p>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-8">
                                <form action="" className="php-email-form" data-aos="fade-up" data-aos-delay="200">
                                    <div className="row gy-4">

                                        <div className="col-md-6">
                                            <input type="text" name="name" className="form-control" placeholder="Your Name" required="" />
                                        </div>

                                        <div className="col-md-6 ">
                                            <input type="email" className="form-control" name="email" placeholder="Your Email" required="" />
                                        </div>

                                        <div className="col-md-12">
                                            <input type="text" className="form-control" name="subject" placeholder="Subject" required="" />
                                        </div>

                                        <div className="col-md-12">
                                            <textarea className="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
                                        </div>

                                        <div className="col-md-12 text-center">
                                            <div className="loading">Loading</div>
                                            <div className="error-message"></div>
                                            <div className="sent-message">Your message has been sent. Thank you!</div>

                                            <button type="submit">Send Message</button>
                                        </div>

                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>

                </section>
            </main>

            <footer id="footer" className="footer light-background">

                <div className="container footer-top">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <a href="index.html" className="logo d-flex align-items-center">
                                <span className="sitename">Medixlab</span>
                            </a>
                            <div className="footer-contact pt-3">
                                <p>34-H Phase 1 DHA,</p>
                                <p>DHA, Lahore, 54000</p>
                                <p className="mt-3"><strong>Phone:</strong> <span>+92 303 4142927</span></p>
                                <p><strong>Email:</strong> <span>hello@medixlab.co</span></p>
                            </div>
                            <div className="social-links d-flex mt-4">
                                <a href=""><i className="bi bi-twitter-x"></i></a>
                                <a href=""><i className="bi bi-facebook"></i></a>
                                <a href=""><i className="bi bi-instagram"></i></a>
                                <a href=""><i className="bi bi-linkedin"></i></a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#">About us</a></li>
                                <li><a href="#">Services</a></li>
                                <li><a href="#">Terms of service</a></li>
                                <li><a href="#">Privacy policy</a></li>
                            </ul>
                        </div>

                        {/* <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><a href="#">Web Design</a></li>
                                <li><a href="#">Web Development</a></li>
                                <li><a href="#">Product Management</a></li>
                                <li><a href="#">Marketing</a></li>
                                <li><a href="#">Graphic Design</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Hic solutasetp</h4>
                            <ul>
                                <li><a href="#">Molestiae accusamus iure</a></li>
                                <li><a href="#">Excepturi dignissimos</a></li>
                                <li><a href="#">Suscipit distinctio</a></li>
                                <li><a href="#">Dilecta</a></li>
                                <li><a href="#">Sit quas consectetur</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Nobis illum</h4>
                            <ul>
                                <li><a href="#">Ipsam</a></li>
                                <li><a href="#">Laudantium dolorum</a></li>
                                <li><a href="#">Dinera</a></li>
                                <li><a href="#">Trodelas</a></li>
                                <li><a href="#">Flexo</a></li>
                            </ul>
                        </div> */}

                    </div>
                </div>

                <div className="container copyright text-center mt-4">
                    <p>© <span>Copyright</span> <strong className="px-1 sitename">Medixlab</strong> <span>All Rights Reserved</span></p>
                    <div className="credits">
                        Designed by <a href="#">Medix lab</a>
                    </div>
                </div>

            </footer>
            <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
  
        </div>
        </>

    )
};
export default LandingPage;