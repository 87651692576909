import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Button, DatePicker, Divider, Form, notification, Upload } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import { Link, useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useStore } from "../../store/root-store";
import moment from "moment/moment";
import { PlusOutlined } from "@ant-design/icons";
import './styles.css';
import PhoneInput from 'react-phone-number-input';
import { TestFieldsRow } from "./testFieldsRow";
import { SubmitButton } from "../../common-components/submitButton";
import { generatePatientReceipt } from "../../utils/functions";
import { DocumentHead } from "../../utils/documentHead";
import { PageLayout } from "../../common-components/pageLayout";

const AddPatients = observer(() => {
  const { getUserData, patientsStore: { creatingRecord, createPatientRecord, fetchPatientRecord }, testsStore: { fetchTestsList, getTestsList }, panelsStore: {fetchPanelsList, getPanelsList}, doctorsStore: {getDoctorsList, fetchDoctorsList} } = useStore('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchTestsList();
    fetchPanelsList();
    fetchDoctorsList();
  }, []);

  const [imageUrl, setImageUrl] = useState();

  const [form] = Form.useForm();

  const getValueFromEvent = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        form.setFieldsValue({
          profile_image: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
    return e && e.fileList;
  };

  const getBase64 = async (img) => {
    const reader = new FileReader();
    await reader.addEventListener('load', () => {
      form.setFieldsValue({
        profile_image: reader.result,
      });
      setImageUrl(reader.result);
    });
    reader.readAsDataURL(img);
    return reader.result;
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      {<PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <div>
      <DocumentHead title={'Patients'} />
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <>
        <PageLayout>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Patients </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Patient</li>
                  </ul>
                  {/* <button onClick={async () => {
                    const retrieveData = await fetchPatientRecord(29);
                    generatePatientReceipt(retrieveData?.data, {});
                    }}>download</button> */}
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <Form form={form} initialValues={{
                      tests: [{}]
                    }} onValuesChange={(val, allValues) => {
                      const totalPrice = allValues?.tests?.filter(z => z?.id)?.reduce((a, b) => a + Number(b?.id?.price), 0);
                      const discountAmountType = allValues?.discount_amount_type?.value;
                      const discountAmount = discountAmountType === 'percent' ? Number(totalPrice / 100 * Number(allValues?.discount_amount || 0)) : Number(allValues?.discount_amount || 0);
                      const payableAmount = Number(totalPrice) - Number(discountAmount);
                      const dueAmount = Number(payableAmount) - Number(allValues?.paid_amount || 0);
                      form.setFieldsValue({
                        total_amount: totalPrice,
                        payable_amount: payableAmount,
                        due_amount: dueAmount,
                      });

                    }} onFinish={async (values) => {
                      const discountAmountType = values?.discount_amount_type?.value;
                      const discountAmount = discountAmountType === 'percent' ? values?.total_amount / 100 * values?.discount_amount : values?.discount_amount;
                      const dataObj = {
                        patient_detail: {
                          age: values?.age,
                          blood_group: values?.blood_group?.value,
                          contact_number: values?.contact_number,
                          date_of_birth: values?.date_of_birth,
                          email: values?.email,
                          first_name: values?.first_name,
                          gender: values?.gender?.value,
                          guardian: values?.guardian,
                          last_name: values?.last_name,
                          nic: values?.nic,
                          passport_number: values?.passport_number,
                          profile_image: values?.profile_image,
                          referred_by: values?.referred_by?.value,
                          title: values?.title?.value,
                          panel: values.panel.value,
                        },
                        tests: values?.tests?.map(z => {
                          return {
                            id: z?.id?.value,
                            test_status: z?.test_status ? 'Urgent' : 'Normal',
                            sample_required: values?.sample_required?.value,
                          }
                        }),
                        "test_invoice": {
                          "discount_amount": Number(discountAmount || 0),
                          "total_amount": values?.total_amount,
                          "paid": Number(values?.paid_amount || 0),
                          "due_amount": Number(values?.due_amount || 0),
                        }
                      }
                      const res = await createPatientRecord(dataObj);
                      if (res?.status_code === 200) {
                        const retrieveData = await fetchPatientRecord(res?.data?.patient_id);
                        if (retrieveData?.status_code === 200) {
                          notification.success({ message: res?.message, description: <div>
                            Patient name: <b>{retrieveData?.data?.first_name || ''} {retrieveData?.data?.last_name || ''}</b>
                            <br />
                            MR #: <b>{retrieveData?.data?.mr_no || ''}</b>
                          </div>, duration: 600, });
                          generatePatientReceipt(retrieveData?.data, getUserData);
                          form.resetFields();
                          navigate('/patientslist')
                        }
                      } else {
                        notification.error({ message: 'Error occured!', description: JSON.stringify(res?.message) })
                      }
                    }}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Patients Details</h4>
                          </div>
                        </div>
                        <div className="createPatientFormWrapper col-12">
                          <div className="profileImageDiv">
                            <Form.Item name={'profile_image'} valuePropName="file" style={{ padding: '28px 20px 20px 28px', border: '2px solid rgba(46, 55, 164, 0.1)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} getValueFromEvent={(val) => {
                              return getBase64(val.file.originFileObj);
                            }}>
                              <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={() => { }}
                              >
                                {imageUrl ? (
                                  <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                      height: '100%',
                                    }}
                                  />
                                ) : (
                                  uploadButton
                                )}
                              </Upload>
                            </Form.Item>
                          </div>
                          <div className="fieldsWrapperDiv row">
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Title
                                </label>
                                <Form.Item name={'title'}>
                                  <Select
                                    options={[
                                      { label: 'Mr.', value: 'Mr.' },
                                      { label: 'Miss.', value: 'Miss.' },
                                    ]}
                                    components={{
                                      IndicatorSeparator: () => null
                                    }}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                        boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                        '&:hover': {
                                          borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                        },
                                        borderRadius: '10px',
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                        transition: '250ms',
                                        width: '35px',
                                        height: '35px',
                                      }),
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <Form.Item name={'first_name'} rules={[{ required: true, message: 'This field is required' }]}>
                                  <div>
                                    <label>
                                      First Name <span className="login-danger">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder=""
                                    />
                                  </div>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <Form.Item name={'last_name'} rules={[{ required: true, message: 'This field is required' }]}>
                                  <div>
                                    <label>
                                      Last Name <span className="login-danger">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder=""
                                    />
                                  </div>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <Form.Item name={'age'} rules={[{ required: true, message: 'This field is required' }]}>
                                  <div>
                                    <label>
                                      Age <span className="login-danger">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder=""
                                    />
                                  </div>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Gender<span className="login-danger">*</span>
                                </label>
                                <Form.Item name={'gender'} rules={[{ required: true, message: 'This field is required' }]}>
                                  <Select
                                    options={[
                                      { label: 'Male', value: 'Male' },
                                      { label: 'Female', value: 'Female' },
                                      { label: 'Baby', value: 'Baby' }
                                    ]}
                                    id="search-commodity"
                                    components={{
                                      IndicatorSeparator: () => null
                                    }}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                        boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                        '&:hover': {
                                          borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                        },
                                        borderRadius: '10px',
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                        transition: '250ms',
                                        width: '35px',
                                        height: '35px',
                                      }),
                                    }}
                                  />
                                  {/* <div className="form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="radio"
                                      name="gender"
                                      className="form-check-input"
                                    />
                                    Male
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="radio"
                                      name="gender"
                                      className="form-check-input"
                                    />
                                    Female
                                  </label>
                                </div> */}
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms cal-icon">
                                <label>
                                  Date Of Birth{" "}
                                  <span className="login-danger">*</span>
                                </label>
                                <Form.Item name={'date_of_birth'} getValueProps={val => {
                                  return moment(val);
                                }} getValueFromEvent={dateObj => {
                                  return moment(dateObj).format('YYYY-MM-DD')
                                }}>
                                  <DatePicker
                                    className="form-control datetimepicker"
                                    suffixIcon={null}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Contact Number <span className="login-danger">*</span>
                                </label>
                                <Form.Item name={'contact_number'} rules={[{ required: true, message: 'This field is required' }, {max: 13, message: 'Maximum 10 digits allowed!'}, {min: 13, message: 'Minimum 10 digits allowed!'}]}>
                                  <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="PK"
                                    countries={['PK']}
                                    className="form-control disable-input-border"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <Form.Item name={'guardian'}>
                                  <div>
                                    <label>
                                      Guardian
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder=""
                                    />
                                  </div>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <Form.Item name={'nic'}>
                                  <div>
                                    <label>
                                      CNIC
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder=""
                                    />
                                  </div>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <Form.Item name={'passport_number'}>
                                  <div>
                                    <label>
                                      Passport
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder=""
                                    />
                                  </div>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <Form.Item name={'email'}>
                                  <div>
                                    <label>
                                      Email
                                    </label>
                                    <input
                                      className="form-control"
                                      type="email"
                                      placeholder=""
                                      autoComplete="no"
                                    />
                                  </div>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <div>
                                  <label>
                                    Blood Group
                                  </label>
                                  <Form.Item name={'blood_group'}>
                                    <Select
                                      options={[
                                        { label: 'A+', value: 'A+' },
                                        { label: 'A-', value: 'A-' },
                                        { label: 'B+', value: 'B+' },
                                        { label: 'B-', value: 'B-' },
                                        { label: 'AB+', value: 'AB+' },
                                        { label: 'AB-', value: 'AB-' },
                                        { label: 'O+', value: 'O+' },
                                        { label: 'O-', value: 'O-' },
                                      ]}
                                      id="search-commodity"
                                      components={{
                                        IndicatorSeparator: () => null
                                      }}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                          boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                          '&:hover': {
                                            borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                          },
                                          borderRadius: '10px',
                                          fontSize: "14px",
                                          minHeight: "45px",
                                        }),
                                        dropdownIndicator: (base, state) => ({
                                          ...base,
                                          transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                          transition: '250ms',
                                          width: '35px',
                                          height: '35px',
                                        }),
                                      }}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                    <label>
                                      Referred by{" "}
                                    </label>
                                <Form.Item name={'referred_by'}>
                                    <Select
                                    options={getDoctorsList?.map(item => {
                                      return {
                                        label: item?.name,
                                        value: item?.id,
                                      }
                                    })}
                                    id="search-commodity"
                                    components={{
                                      IndicatorSeparator: () => null
                                    }}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                        boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                        '&:hover': {
                                          borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                        },
                                        borderRadius: '10px',
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                        transition: '250ms',
                                        width: '35px',
                                        height: '35px',
                                      }),
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Panel <span className="login-danger">*</span>
                                </label>
                                <Form.Item name={'panel'} rules={[{ required: true, message: 'This field is required' }]}>
                                  <Select
                                    options={getPanelsList?.map(item => {
                                      return {
                                        label: item?.name,
                                        value: item?.id,
                                      }
                                    })}
                                    id="search-commodity"
                                    components={{
                                      IndicatorSeparator: () => null
                                    }}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                        boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                        '&:hover': {
                                          borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                        },
                                        borderRadius: '10px',
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                        transition: '250ms',
                                        width: '35px',
                                        height: '35px',
                                      }),
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            {/* <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-top-form">
                              <Form.Item getValueFromEvent={getValueFromEvent} name={'profile_image'} rules={[{required: true, message: 'This field is required'}]}>
                                <div>
                                  <label className="local-top">
                                    Profile Image <span className="login-danger">*</span>
                                  </label>
                                  <div className="settings-btn upload-files-avator">
                                    <input
                                      type="file"
                                      accept="image/*"
                                      name="image"
                                      id="file"
                                      className="hide-input"
                                    />
                                    <label htmlFor="file" className="upload">
                                      Choose File
                                    </label>
                                  </div>
                                </div>
                              </Form.Item>
                            </div>
                          </div> */}
                            <div className="col-12">
                              <Divider orientation="left" style={{ marginTop: 0 }}>Patient Tests</Divider>
                            </div>
                            <Form.List name="tests">
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => {
                                    return <TestFieldsRow key={key} remove={remove} name={name} getTestsList={getTestsList} {...restField} />
                                  })}
                                  <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                      Add test
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>

                            <div className="col-12 col-md-6 col-xl-6 offset-md-6 offset-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Total amount
                                </label>
                                <Form.Item name={'total_amount'}>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    readOnly
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6 offset-md-6 offset-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Discount amount
                                </label>
                                <div style={{ position: 'relative' }}>
                                  <Form.Item name={'discount_amount'}>
                                    <input type="text" className="form-control" placeholder="" />
                                  </Form.Item>

                                  <div style={{ position: 'absolute', right: 0, top: 0 }}>
                                    <Form.Item name={'discount_amount_type'}>
                                      <Select
                                        options={[
                                          { label: 'Percentage', value: 'percent' },
                                          { label: 'Rupees', value: 'amount' }
                                        ]}
                                        components={{
                                          IndicatorSeparator: () => null
                                        }}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                            boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                            '&:hover': {
                                              borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                            },
                                            borderRadius: '10px',
                                            fontSize: "14px",
                                            minHeight: "45px",
                                          }),
                                          dropdownIndicator: (base, state) => ({
                                            ...base,
                                            transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                            transition: '250ms',
                                            width: '35px',
                                            height: '35px',
                                          }),
                                        }}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6 offset-md-6 offset-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Payable amount
                                </label>
                                <Form.Item name={'payable_amount'}>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    readOnly
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6 offset-md-6 offset-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Due amount
                                </label>
                                <Form.Item name={'due_amount'}>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    readOnly
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6 offset-md-6 offset-xl-6">
                              <div className="form-group local-forms">
                                <Form.Item name={'paid_amount'}>
                                  <div>
                                    <label>
                                      Paid amount
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder=""
                                    />
                                  </div>
                                </Form.Item>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <SubmitButton classnames={'btn btn-primary submit-form me-2'} loading={creatingRecord} buttonText={'Save'} />
                            <button
                              className="btn btn-primary cancel-form"
                              onClick={e => {
                                e?.preventDefault();
                                navigate(`/patientslist`)
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </PageLayout>
      </>
    </div>
  );
});

export default AddPatients;
