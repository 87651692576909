import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Table } from "antd";
import {onShowSizeChange,itemRender}from  '../Pagination'
import { tabledata } from "../GlobalData/TableData";
import { Link } from "react-router-dom";
import { PageLayout } from "../../common-components/pageLayout";
const DataTable = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const onSelectChange = (newSelectedRowKeys) => {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };


       const columns = [

        {
            title:"Name",
            dataIndex: "Name",
                sorter: (a, b) => a.Name.length - b.Name.length
        },
        {
            title:"Position",
            dataIndex: "Position",
                sorter: (a, b) => a.Position.length - b.Position.length
        },
        {
            title:"Office",
            dataIndex: "Office",
                sorter: (a, b) => a.Office.length - b.Office.length
        },
        {
            title:"Age",
            dataIndex: "Age",
                sorter: (a, b) => a.Age.length - b.Age.length,
        }, {
            title:"Startdate",
            dataIndex: "Startdate",
                sorter: (a, b) => a.Startdate.length - b.Startdate.length
        }, {
            title:"Salary",
            dataIndex: "Salary",
                sorter: (a, b) => a. Salary.length - b. Salary.length
        },

    ]
  return (
    <>
      <Header />
      <Sidebar id='menu-item16' id1='menu-items16' activeClassName='data-table'/>
      <>
        <PageLayout>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right"/>
                        </i>
                    </li>
                    <li className="breadcrumb-item active">Data Tables</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card-box">
                  <div className="card-block">
                    <h6 className="card-title text-bold">Default Datatable</h6>
                    <p className="content-group">
                      This is the most basic example of the datatables with zero
                      configuration. Use the <code>.datatable</code> class to
                      initialize datatables.
                    </p>
                    <div className="table-responsive">
                    <Table
                        pagination={{
                          total: tabledata.length,
                          showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,
                           onShowSizeChange: onShowSizeChange,
                           itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={tabledata}

                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PageLayout>
      </>
    </>
  );
};

export default DataTable;
