import { toJS } from 'mobx';
import {flow, types} from 'mobx-state-tree';
import api from '../api';

export const ConfiguratorStore = types.model({
    configuratorListLoading: types.boolean,
    configuratorList: types.array(types.model({
        company: types.number,
        created_at: types.string,
        id: types.number,
        is_active: types.boolean,
        name: types.string,
        updated_at: types.string,
    })),
}).views(self => ({
    get getConfiguratorData() {
      return toJS(self.configuratorList);
    },
  })).actions(self => {
  const fetchConfiguratorData = flow(function* () {
    try {
      const response = yield api.get(`/labs/configurator_list/`);
      self.configuratorList = response?.data?.data;
      self.configuratorListLoading = false;
    } catch (e) {
      return e?.response?.data;
    }
  });
  return {
    fetchConfiguratorData,
  };
});


export function initConfiguratorStore() {
    return ConfiguratorStore.create({
        configuratorList: [],
        configuratorListLoading: true,
    });
}
