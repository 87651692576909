import React from 'react'
import Header from '../Header';
import Sidebar from '../Sidebar';
import ReactTagsInput from '../ReactTagInput';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';

const Settingsseo = () => {
  return (
    <div>
        <Header />
        <Sidebar />
        <div className="page-wrapper">
  {/* Page Content */}
  <div className="content container-fluid">
    {/* Page Header */}
    <div className="page-header">
      <div className="row">
        <div className="col-sm-12">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="index.html">Dashboard </Link>
            </li>
            <li className="breadcrumb-item">
              <i className="feather-chevron-right">
              <FeatherIcon icon="chevron-right"/>
              </i>
            </li>
            <li className="breadcrumb-item active">Settings</li>
          </ul>
        </div>
      </div>
    </div>
    {/* /Page Header */}
    <div className="settings-menu-links">
      <ul className="nav nav-tabs menu-tabs">
        <li className="nav-item ">
          <Link className="nav-link" to="/settings">
            General Settings
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/localization">
            Localization
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/paymentsetting">
            Payment Settings
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/settingsemail">
            Email Settings
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/settingssocialmedia">
            Social Media Login
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/settingssociallink">
            Social Links
          </Link>
        </li>
        <li className="nav-item active">
          <Link className="nav-link" to="/settingsseo">
            SEO Settings
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/settingsthem">
            Theme Settings
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/settingschangepassword">
            Change Password
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/settingsothers">
            Others
          </Link>
        </li>
      </ul>
    </div>
    <div className="row">
      <div className="col-md-6">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">SEO Settings</h5>
          </div>
          <div className="card-body pt-0">
            <form>
              <div className="settings-form">
                <div className="form-group form-placeholder">
                  <label>
                    Meta Title <span className="star-red">*</span>
                  </label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label>
                    Meta Keywords <span className="star-red">*</span>
                  </label>
                  <ReactTagsInput />
                  {/* <input
                    type="text"
                    data-role="tagsinput"
                    className="input-tags form-control"
                    placeholder="Meta Keywords"
                    name="services"
                    defaultValue="Lorem,Ipsum"
                    id="services"
                  /> */}
                </div>
                <div className="form-group">
                  <label>
                    Meta Description <span className="star-red">*</span>
                  </label>
                  <textarea className="form-control" defaultValue={""} />
                </div>
                <div className="form-group mb-0">
                  <div className="settings-btns">
                    <button
                      type="submit"
                      className="border-0 btn btn-primary btn-gradient-primary btn-rounded me-2"
                    >
                      Submit
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary btn-rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    </div>
  )
}

export default Settingsseo;
