import { toJS } from 'mobx';
import {cast, flow, types} from 'mobx-state-tree';
import api from '../api';


const testRecordModel = types.model({ 
  age: types.maybeNull(types.string),
  barcode_image: types.maybeNull(types.string),
  blood_group: types.maybeNull(types.string),
  city: types.maybeNull(types.number),
  company: types.maybeNull(types.number),
  contact_number: types.maybeNull(types.string),
  country: types.maybeNull(types.number),
  created_at: types.maybeNull(types.string),
  date_of_birth: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  first_name: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  is_active: types.maybeNull(types.boolean),
  last_name: types.maybeNull(types.string),
  middle_name: types.maybeNull(types.string),
  nic: types.maybeNull(types.string),
  panel: types.maybeNull(types.string),
  passport_number: types.maybeNull(types.string),
  profile_image: types.maybeNull(types.string),
  qrcode_image: types.maybeNull(types.string),
  referred_by: types.maybeNull(types.string),
  registered_by: types.maybeNull(types.number),
  state: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
});

const testSingleRecordModel = types.model({
  age: types.maybeNull(types.string),
  barcode_image: types.maybeNull(types.string),
  blood_group: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  company: types.maybeNull(types.number),
  contact_number: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  created_at: types.maybeNull(types.string),
  date_of_birth: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  first_name: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  is_active: types.maybeNull(types.boolean),
  last_name: types.maybeNull(types.string),
  middle_name: types.maybeNull(types.string),
  mr_no: types.maybeNull(types.string),
  nic: types.maybeNull(types.string),
  panel: types.maybeNull(types.string),
  passport_number: types.maybeNull(types.string),
  profile_image: types.maybeNull(types.string),
  qrcode_image: types.maybeNull(types.string),
  referred_by: types.maybeNull(types.string),
  registered_at: types.maybeNull(types.string),
  registered_by: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  test_detail: types.maybeNull(types.model({
  created_at: types.maybeNull(types.string),
  test_detail_id: types.maybeNull(types.string),
  discount: types.maybeNull(types.number),
  due_amount: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  is_active: types.maybeNull(types.boolean),
  lab_no: types.maybeNull(types.number),
  paid_amount: types.maybeNull(types.number),
  test: types.maybeNull(types.number),
  // sample_required
  // : 
  // null
  // test_details: types.maybeNull(types.string),
  test_status: types.maybeNull(types.string),
  tests: types.maybeNull(types.array(types.maybeNull(types.model({
        created_at: types.maybeNull(types.string),
        created_by: types.maybeNull(types.number),
        description: types.maybeNull(types.string),
        id: types.maybeNull(types.number),
        is_active: types.maybeNull(types.boolean),
        name: types.maybeNull(types.string),
        price: types.maybeNull(types.string),
        report_time: types.maybeNull(types.string),
        reporting_time: types.maybeNull(types.string),
        updated_at: types.maybeNull(types.string),
      })))),
    total_amount: types.maybeNull(types.number),
    updated_at: types.maybeNull(types.string),
  })), 
  title: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
});

const UpdatedTestModel = types.model({
  name: types.maybeNull(types.string),
  price: types.maybeNull(types.union(types.number, types.string)),
  report_time: types.maybeNull(types.string),
  normal_range: types.maybeNull(types.string),
  unit: types.maybeNull(types.string),
  sample_required: types.maybeNull(types.string),
})

const testListItemModel = types.model({
  created_at: types.maybeNull(types.string),
  created_by: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  is_active: types.boolean,
  name: types.maybeNull(types.string),
  price: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  report_time: types.maybeNull(types.string),
  normal_range: types.maybeNull(types.string),
  unit: types.maybeNull(types.string),
  test_type: types.maybeNull(types.string),
  sample_required: types.maybeNull(types.string),
  source: types.maybeNull(types.string),
  updated_test: types.maybeNull(UpdatedTestModel),
})

const departmentsListItemModel = types.model({
  created_at: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  head: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  is_active: types.maybeNull(types.boolean),
  name: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
})
export const TestsStore = types.model({
    testsListLoading: types.boolean,
    testRecordLoading: types.boolean,
    pageNumber: types.number,
    pageSize: types.number,
    totalCount: types.maybeNull(types.number),
    updatingRecord: types.boolean,
    testsList: types.array(testListItemModel),
    departmentsList: types.array(departmentsListItemModel),
    testsListIndividuals: types.array(testListItemModel),
    testRecord: types.maybeNull(testSingleRecordModel),
}).views(self => ({
    get getTestsList() {
      return toJS(self.testsList);
    },
    get getDepartmentsList() {
      return toJS(self.departmentsList);
    },
    get getTestsListOptions() {
      return toJS(self.testsListIndividuals);
    },
    get getTestRecord() {
      return toJS(self.testRecord);
    },
  })).actions(self => {
    const fetchTestsList = flow(function* (query='') {
      self.testsListLoading  = true;
      try {
        const response = yield api.get(`/labs/tests_listing/?query=${query}&page=${self.pageNumber}&per_page=${self.pageSize}`);
        self.testsList = response?.data?.data;
        self.totalCount = response?.data?.total_count;
        self.testsListLoading = false;
      } catch (e) {
        self.testsListLoading = false;
        if (e?.response?.data?.detail === 'Invalid page.' && self.pageNumber !== 1) {
          self.pageNumber = self.page - 1;
          fetchTestsList();
        }
        return Promise.reject(e);
      }
    });
    const fetchDepartmentsList = flow(function* () {
      try {
        const response = yield api.get(`/labs/department_list/`);
        console.log('qqqqqqres', response)
        self.departmentsList = response?.data?.data;
      } catch (e) {
        return Promise.reject(e);
      }
    });
    const fetchTestsListIndividuals = flow(function* () {
      try {
        const response = yield api.get(`/labs/individual_test/`);
        self.testsListIndividuals = response?.data?.data;
      } catch (e) {
        return Promise.reject(e);
      }
    });
  const fetchTestRecord = flow(function* (id) {
    self.testRecordLoading = true;
    self.testRecord = null;
    try {
      const response = yield api.get(`/labs/get_test/${id}/`);
      // self.testRecord = response?.data?.data;
      self.testRecordLoading = false;
      return response?.data;
    } catch (e) {
      return Promise.reject(e);
    }
  });
  const createTestRecord = flow(function* (payload) {
    self.creatingRecord = true;
    try {
      const response = yield api.post(`/labs/add_test/`, payload);
      self.creatingRecord = false;
      return response?.data;
    } catch (e) {
      self.creatingRecord = false;
      return Promise.reject(e);
    }
  });
  const updateTestRecord = flow(function* (id, payload) {
    self.updatingRecord = true;
    try {
      const response = yield api.put(`/labs/update_test/${id}/`, payload);
      self.updatingRecord = false;
      return response?.data;
    } catch (e) {
      self.updatingRecord = false;
      return e?.response?.data;
    }
  });
  const deleteTest = flow(function* (id) {
    try {
      const response = yield api.delete(`/labs/delete_test/${id}/`);
      return response?.data;
    } catch (e) {
      self.creatingRecord = false;
      return e?.response?.data;
    }
  });
  const createPatientTest = flow(function* (payload) {
    try {
      const response = yield api.post(`/labs/create_patient_test/`, payload);
      return response?.data;
    } catch (e) {
      return e?.response?.data;
    }
  });
  const deletePatientTest = flow(function* (id) {
    try {
      const response = yield api.delete(`/labs/delete_patient_test/${id}/`);
      return response?.data;
    } catch (e) {
      return e?.response?.data;
    }
  });
  const updateTestDetail = flow(function* (id, payload) {
    try {
      const response = yield api.put(`/labs/update_test_detail/${id}/`, payload);
      return response?.data;
    } catch (e) {
      return e?.response?.data;
    }
  });
  const handlePagination = (page, pageSize) => {
    self.pageNumber = page;
    self.pageSize = pageSize;
    fetchTestsList();
  };
  const resetPagination = () => {
    self.pageNumber = 1;
    self.pageSize = 10;
  };
  return {
    createTestRecord,
    fetchTestRecord,
    fetchTestsList,
    updateTestRecord,
    deleteTest,
    fetchTestsListIndividuals,
    fetchDepartmentsList,
    createPatientTest,
    deletePatientTest,
    updateTestDetail,
    handlePagination,
    resetPagination,
  };
});


export function initTestsStore() {
    return TestsStore.create({
        testsList: [],
        testsListLoading: true,
        testRecordLoading: true,
        testRecord: null,
        updatingRecord: false,
        pageNumber: 1,
        pageSize: 10,
    });
}
