import { observer } from "mobx-react-lite"
import { Helmet } from "react-helmet"
import { useStore } from "../store/root-store"

export const DocumentHead = observer(({title}) => {
    const {getUserData} = useStore('');
    return <>
      <Helmet>
        <link rel="icon" type="image/png" href={`${getUserData?.company_logo || "./favicon.png"}`} />
        <title>{`${title} - ${getUserData?.company_name || 'Medix lab'}`}</title>
      </Helmet>
    </>
})