import { useEffect, useState } from "react";
import { chaticon4 } from '../imagepath';
import { observer } from "mobx-react-lite";
import styled from "styled-components";

export const ResultInputBox = observer(({ z, getPatientRecord, postTestResult, fetchPatientRecord, patientTestId }) => {
    const [value, setValue] = useState('');
    useEffect(() => {
        setValue(z?.result?.current_result || '');
    }, [z?.result?.current_result])
    return <Wrapper>
        <span className="ms-1">Normal range: {z?.normal_range} {z?.unit}</span>
        <InputStyled className='form-control' placeholder='Add result' value={value} onChange={e => setValue(e.target.value)} />
        <img src={chaticon4} alt="#" className="ms-1 ms-auto" style={z?.result?.current_result ? { filter: 'grayscale(100%)', cursor: 'not-allowed' } : { cursor: 'pointer' }} onClick={async () => {
            if (!z?.result?.current_result) {
                const payloadObj = {
                    test_detail_id: getPatientRecord?.test_detail?.test_detail_id,
                    test: z?.id,
                    result: value || '',
                    patient_test: patientTestId || '',
                };
                
                const res = await postTestResult(payloadObj);
                if (res?.status_code === 200) {
                    fetchPatientRecord();
                }
            }
        }} />
    </Wrapper>
});

const Wrapper = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
`;

const InputStyled = styled.input`
    max-height: 34px;
    min-height: 34px;
    width: 120px;
`;

