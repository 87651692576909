import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Divider, Form, notification, Table, Upload } from "antd";
import Header from '../Header';
import Sidebar from '../Sidebar';
import { onShowSizeChange, itemRender } from '../Pagination'
import { editicon2, imagesend, pdficon, pdficon3, pdficon4, plusicon, searchnormal } from '../imagepath';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/root-store';
import moment from 'moment';
import { Check, CheckOutlined, DeleteForever, Edit, ForwardToInbox } from '@mui/icons-material';
import { generatePatientReceipt } from '../../utils/functions';
import PhoneInput from 'react-phone-number-input';
import Select from "react-select";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { TestFieldsRow } from './testFieldsRow';
import dayjs from 'dayjs';
import { DocumentHead } from '../../utils/documentHead';
import { PageLayout } from '../../common-components/pageLayout';


const PatientsList = observer(() => {
  const { getUserData, patientsStore: { deletePatient, getPatientsData, resetPagination, patientsListLoading, handlePagination, totalCount, getPatientRecord, updatePatientRecord, fetchPatientsData, fetchPatientRecord }, testsStore: { getTestsList, fetchTestsList, createPatientTest, deletePatientTest }, panelsStore: { fetchPanelsList, getPanelsList }, doctorsStore: { getDoctorsList, fetchDoctorsList } } = useStore('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPanelsList();
    fetchPatientsData();
  }, []);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "MR #",
      dataIndex: "mr_no",
      sorter: (a, b) => Number(a.mr_no) - Number(b.mr_no),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name.length - b.first_name.length,
      render: (text, record) => (
        <>
          <h2 className="profile-image">
            <Link to={`/patientdetails/${record?.id}`} className="avatar avatar-sm me-2">
              <img
                className="avatar-img rounded-circle"
                src={record.profile_image}
                style={{ height: '28px', width: '28px' }}
                alt="#"
              />
            </Link>
            {/* <Link to={`/editpatients/${record?.id}?is_old_record=${record?.mr_no}`}>{record?.title} {record?.first_name} {record?.middle_name} {record?.last_name}</Link> */}
            <Link to={`/patientdetails/${record?.id}`}>{record?.title} {record?.first_name} {record?.middle_name} {record?.last_name}</Link>
          </h2>

        </>
      )
    },
    {
      title: "Age",
      dataIndex: "age",
      sorter: (a, b) => a.age - b.age
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length
    },
    // {
    //     title:"Date of birth",
    //     dataIndex: "date_of_birth",
    //         sorter: (a, b) => new Date(a.date_of_birth) - new Date(b.date_of_birth)
    // },
    // {
    //     title:"Mobile",
    //     dataIndex: "contact_number",
    //         sorter: (a, b) => a.contact_number.length - b.contact_number.length,
    //         render: (text, record) => (
    //             <>

    //                     <Link to="#">{record.contact_number}</Link>

    //             </>
    //         )
    // },
    {
      title: "Panel",
      dataIndex: "panel",
      sorter: (a, b) => a.panel.length - b.panel.length
    },
    //     {
    //       title:"CNIC",
    //       dataIndex: "nic",
    //           sorter: (a, b) => a.nic?.length - b.nic?.length
    //   },
    //   {
    //     title:"Passport",
    //     dataIndex: "passport_number",
    //         sorter: (a, b) => a.passport_number?.length - b.passport_number?.length
    // },
    //       {
    //           title:"Email",
    //           dataIndex: "email",
    //               sorter: (a, b) => a.email?.length - b.email?.length
    //       }, 
    //       {
    //         title:"Blood group",
    //         dataIndex: "blood_group",
    //             sorter: (a, b) => a.blood_group?.length - b.blood_group?.length
    //     },
    //     {
    //       title:"Referred by",
    //       dataIndex: "referred_by",
    //           sorter: (a, b) => a.reffered_by?.length - b.reffered_by?.length
    //   },
    //       {
    //           title:"Joining Date",
    //           dataIndex: "created_at",
    //           sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    //           render: (text, record) => {
    //             return <>{text ? moment(text)?.format('DD MMM YYYY') : ''}</>
    //           }
    //       },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (text, record) => (
        <>
          <div className='text-center' onClick={e => e.stopPropagation()}>
            <span style={{ cursor: 'pointer' }} className="me-3" onClick={async () => {
              const retrieveData = await fetchPatientRecord(record?.id);
              generatePatientReceipt(retrieveData?.data, getUserData);
            }}>
              <img src={pdficon} alt="#" />
            </span>
            <Link to={`/editpatients/${record?.id}`} className="me-3">
              <Edit style={{ color: '#2e37a4' }} />
            </Link>
            <span style={{ cursor: 'pointer' }} className="me-3">
              <ForwardToInbox style={{ color: '#00d5c7' }} />
            </span>
            <span style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#delete_patient" onClick={() => setSelectedDeleteId(record?.id)}>
              <DeleteForever style={{ color: '#ff0000' }} />
            </span>
            {/* <div className="dropdown dropdown-action">
                <Link
                  to="#"
                  className="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-v" />
                </Link>
                <div className="dropdown-menu dropdown-menu-end">
                  <Link className="dropdown-item" to={`/editpatients/${record?.id}`}>
                    <i className="far fa-edit me-2" />
                    Edit
                  </Link>
                  <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#delete_patient">
                    <i className="fa fa-trash-alt m-r-5"></i> Delete</Link>
                </div>
              </div> */}
          </div>
        </>
      ),
    },

  ]
  const [timer, setTimer] = useState(null);
  const onSearchHandler = e => {
    const value = e.target.value || '';
    setQuery(value);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        resetPagination();
        fetchPatientsData(value);
      }, 600),
    );
  };
  return (
    <>
    <DocumentHead title={'Patients'} />
      <Header />
      <Sidebar id='menu-item2' id1='menu-items2' activeClassName='patient-list' />
      <PageLayout>
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Patients </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Patients List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk" style={{ justifyContent: 'space-between' }}>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <h3>Patients List</h3>
                            <div className="top-nav-search table-search-blk">
                              <div className='search-form-style'>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                  value={query}
                                  onChange={onSearchHandler}
                                />
                                <Link className="btn">
                                  <img
                                    src={searchnormal}
                                    alt="#"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="doctor-search-blk">
                            <div className="add-group">
                              <Link
                                to="/addpatients"
                                className="btn btn-primary add-pluss ms-2"
                                style={{ width: 'fit-content' }}
                              >
                                <img src={plusicon} alt="#" />&nbsp;<span style={{ fontWeight: '600' }}>Patient</span>
                              </Link>
                              {/* <Link
                          to="#"
                          className="btn btn-primary doctor-refresh ms-2"
                        >
                          <img src={refreshicon}alt="#" />
                        </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-auto text-end float-end ms-auto download-grp">
                  <Link to="#" className=" me-2">
                    <img src={pdficon} alt="#" />
                  </Link>
                  <Link to="#" className=" me-2">
                  </Link>
                  <Link to="#" className=" me-2">
                    <img src={pdficon3} alt="#" />
                  </Link>
                  <Link to="#">
                    <img src={pdficon4} alt="#" />
                  </Link>
                </div> */}
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      style={{ whiteSpace: 'nowrap' }}
                      pagination={{
                        total: totalCount,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                        onChange: handlePagination,
                      }}
                      onRow={record => {
                        return {
                          onClick: () => {
                            navigate(`/patientdetails/${record?.id}`)
                          },
                        };
                      }}
                      columns={columns}
                      dataSource={getPatientsData}
                      loading={patientsListLoading}
                      // rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageLayout>
      <div id="delete_patient" className="modal fade delete-modal" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={imagesend} alt="#" width={50} height={46} />
              <h3>Are you sure want to delete this ?</h3>
              <div className="m-t-20">
                {" "}
                <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                  Close
                </Link>
                <button className="btn btn-danger" data-bs-dismiss="modal" onClick={async () => {
                    await deletePatient(selectedDeleteId);
                    fetchPatientsData();
                  }}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
})

export default PatientsList;
