import { Navigate } from 'react-router-dom'
import Login from './components/pages/login'
import { observer } from 'mobx-react-lite';
import LandingPage from './components/pages/landingPage/LandingPage';

export const InitialRoute = observer(() => {
  let auth = localStorage.getItem('access_token');
return (
    auth ? <Navigate to='/patientslist'/> : <LandingPage />
  )
})