import Select from "react-select";
import { observer } from "mobx-react-lite";

export const CommonSelect = observer(({ options , ...props}) => {
    return (
        <Select
            options={options}
            components={{
                IndicatorSeparator: () => null
            }}
            menuPortalTarget={document.body}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                    boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                    '&:hover': {
                        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                    },
                    borderRadius: '10px',
                    fontSize: "14px",
                    minHeight: "45px",
                }),
                dropdownIndicator: (base, state) => ({
                    ...base,
                    transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                    transition: '250ms',
                    width: '35px',
                    height: '35px',
                }),
                menuPortal: (base, state) => ({
                    ...base,
                    zIndex: 9999,
                })
            }}
            {...props}
        />
    )
})