import axios from "axios";
import { BASE_URL } from "./constants";
import { notification } from "antd";

const api = axios.create({
  // baseURL: process.env.REACT_APP_API_BASE_URL,
  baseURL: BASE_URL,
});

const getUserToken = async () => {
  return new Promise((resolve, reject) => {
    let token;
    token = localStorage.getItem('access_token');
    if (token) {
      resolve(token);
    } else {
      resolve(null);
    }
  });
};
api.interceptors.request.use(async (request) => {
  const token = await getUserToken();

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  } else {
    // prompt user to login?
  }

  return request;
});


api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 500) {
      notification.error({message: "An error occurred on the server. Please try again later."});
    }

    if (error.response && error.response.status === 401) {
      notification.error({message: "You are not authorized. Please log in."});
    }
    return Promise.reject(error);
  }
);

export default api;
