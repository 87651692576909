import { LoadingOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";

export const SubmitButton = observer(({loading, classnames, buttonText}) => {
    return (
        <button type="submit"
            className={`${classnames}${loading ? ' disabled' : ''}`}
        >
            {loading ? <><LoadingOutlined style={{ fontSize: 16 }} spin />&nbsp;</> : <></>}{buttonText}
        </button>
    )
})