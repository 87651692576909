import { toJS } from 'mobx';
import {flow, types} from 'mobx-state-tree';
import api from '../api';

export const DoctorsStore = types.model({
  updatingRecord: types.boolean,
  creatingRecord: types.boolean,
  doctorsListLoading: types.boolean,
  doctorsList: types.array(types.model({
      address: types.maybeNull(types.string),
      contact_no: types.maybeNull(types.string),
      created_at: types.maybeNull(types.string),
      email: types.maybeNull(types.string),
      hospital_name: types.maybeNull(types.string),
      id: types.maybeNull(types.number),
      is_active: types.maybeNull(types.boolean),
      name: types.maybeNull(types.string),
      specialty: types.maybeNull(types.string),
      updated_at: types.maybeNull(types.string),
  })),
}).views(self => ({
    get getDoctorsList() {
      return toJS(self.doctorsList);
    },
  })).actions(self => {
  const fetchDoctorsList = flow(function* () {
    self.doctorsListLoading = true;
    try {
      const response = yield api.get(`/labs/doctor_list/`);
      self.doctorsList = response?.data?.data;
      self.doctorsListLoading = false;
    } catch (e) {
      return e?.response?.data;
    }
  });
  const fetchDoctorRecord = flow(function* () {
    try {
      const response = yield api.get(`/labs/doctor_list/`);
      // self.doctorsList = response?.data?.data;
      return response?.data?.data;
    } catch (e) {
      return Promise.reject(e);
    }
  });
  const createDoctorRecord = flow(function* (payload) {
    self.creatingRecord = true;
    try {
      const response = yield api.post(`/labs/create_doctor/`, payload);
      self.creatingRecord = false;
      return response?.data;
    } catch (e) {
      self.creatingRecord = false;
      return e?.response?.data;
    }
  });
  const updateDoctorRecord = flow(function* (id, payload) {
    self.updatingRecord = true;
    try {
      const response = yield api.put(`/labs/update_doctor/${id}/`, payload);
      console.log('response', response)
      self.updatingRecord = false;
      return response?.data;
    } catch (e) {
      self.updatingRecord = false;
      return e?.response?.data;
    }
  });
  const deleteDoctor = flow(function* (id) {
    try {
      const response = yield api.delete(`/labs/delete_doctor/${id}/`);
      return response?.data;
    } catch (e) {
      return e?.response?.data;
    }
  });
  return {
    fetchDoctorsList,
    createDoctorRecord,
    updateDoctorRecord,
    deleteDoctor,
    fetchDoctorRecord,
  };
});


export function initDoctorsStore() {
    return DoctorsStore.create({
        doctorsList: [],
        doctorsListLoading: true,
        updatingRecord: false,
        creatingRecord: false,
    });
}
