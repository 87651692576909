import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Divider, Form, notification, Table, Upload } from "antd";
import Header from '../Header';
import Sidebar from '../Sidebar';
import { onShowSizeChange, itemRender } from '../Pagination'
import { calendar, editicon2, empty_wallet, imagesend, morning_img_01, pdficon, pdficon3, pdficon4, plusicon, profile_add, scissor } from '../imagepath';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/root-store';
import moment from 'moment';
import { Check, CheckOutlined, DeleteForever, Edit, ForwardToInbox } from '@mui/icons-material';
import { generatePatientReceipt } from '../../utils/functions';
import PhoneInput from 'react-phone-number-input';
import Select from "react-select";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { TestFieldsRow } from './testFieldsRow';
import dayjs from 'dayjs';
import CountUp from "react-countup";
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { DocumentHead } from '../../utils/documentHead';
import { PageLayout } from '../../common-components/pageLayout';


const PatientsStats = observer(() => {
  const { getUserData, patientsStore: { patientStats, fetchPatientStats, patientsListLoading, getPatientRecord, updatePatientRecord, fetchPatientsData, fetchPatientRecord }, panelsStore: { getPanelsList, fetchPanelsList }, doctorsStore: { getDoctorsList, fetchDoctorsList } } = useStore('');
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedPanel, setSelectedPanel] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const params = {
    start_date: dayjs(startDate)?.format('YYYY-MM-DD'),
    end_date: dayjs(endDate)?.format('YYYY-MM-DD'),
    panel: selectedPanel ? selectedPanel?.value : '',
    doctor: selectedDoctor ? selectedDoctor?.value : '',
  }
  useEffect(() => {
    fetchPatientStats(params);
    fetchDoctorsList();
    fetchPanelsList();
  }, []);

  const handleFilterSubmit = () => {
    fetchPatientStats(params)
  }
  
  return (
    <>
    <DocumentHead title={'Patients'} />
      <Header />
      <Sidebar id='menu-item2' id1='menu-items2' activeClassName='patient-stats' />
      <PageLayout>
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Patients </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Patients Stats</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      Good Morning, <span>{getUserData?.full_name || getUserData?.first_name || ''}</span>
                    </h2>
                    <p>Have a nice day at work</p>
                  </div>
                </div>
                <div className="col-md-6 position-blk">
                  <div className="morning-img">
                    <img src={morning_img_01}
                     alt="#" />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <div className="staff-search-table">
                      <div className="row">
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms cal-icon">
                            <label>From </label>
                            <DatePicker className="form-control datetimepicker" onChange={setStartDate} value={startDate}
                              suffixIcon={null}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms cal-icon">
                            <label>To </label>
                            <DatePicker className="form-control datetimepicker" onChange={setEndDate} value={endDate}
                              suffixIcon={null}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-2">
                          <div className="form-group local-forms">
                            <label>Panel</label>
                            <Select
                            onChange={setSelectedPanel}
                            value={selectedPanel}
                              options={getPanelsList?.map(item => {
                                return {
                                  label: item?.name,
                                  value: item?.id,
                                }
                              })}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-2">
                          <div className="form-group local-forms">
                            <label>Doctor</label>
                            <Select
                            onChange={setSelectedDoctor}
                            value={selectedDoctor}
                              options={getDoctorsList?.map(item => {
                                return {
                                  label: item?.name,
                                  value: item?.id,
                                }
                              })}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-2">
                          <div className="doctor-submit" style={{marginBottom: '25px'}}>
                            <button
                              className="btn btn-primary submit-list-form me-2"
                              onClick={handleFilterSubmit}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={calendar}  alt="#" />
                  </div>
                  <div className="dash-content dash-count flex-grow-1">
                    <h4>Total amount</h4>
                    <h2>
                      {" "}
                      <CountUp delay={0.4} end={patientStats?.total_amount} duration={0.6} />
                    </h2>
                    <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1" >
                          <FeatherIcon icon="arrow-up-right"/>
                        </i>
                        40%
                      </span>{" "}
                      vs last month
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={profile_add}  alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Patient count</h4>
                    <h2>
                      <CountUp delay={0.4} end={patientStats?.patient_count} duration={0.6} />
                    </h2>
                    <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right" />
                          </i>
                        20%
                      </span>{" "}
                      vs last month
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={scissor} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Discount</h4>
                    <h2>
                      <CountUp delay={0.4} end={patientStats?.discount} duration={0.6} />
                    </h2>
                    <p>
                      <span className="negative-view">
                        <i className="feather-arrow-down-right me-1">
                          <FeatherIcon icon="arrow-down-right"/>
                          </i>
                        15%
                      </span>{" "}
                      vs last month
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={empty_wallet} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Paid amount</h4>
                    <h2>
                      <CountUp delay={0.4} end={patientStats?.paid_amount} duration={0.6} />
                    </h2>
                    <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right"/>
                          </i>
                        30%
                      </span>{" "}
                      vs last month
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="dash-widget">
                  <div className="dash-boxs comman-flex-center">
                    <img src={empty_wallet} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Due amount</h4>
                    <h2>
                      <CountUp delay={0.4} end={patientStats?.due_amount} duration={0.6} />
                    </h2>
                    <p>
                      <span className="passive-view">
                        <i className="feather-arrow-up-right me-1">
                          <FeatherIcon icon="arrow-up-right"/>
                          </i>
                        30%
                      </span>{" "}
                      vs last month
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </PageLayout>
    </>

  )
})

export default PatientsStats;
