import React from "react";
import {
  login02,
  loginicon01,
  loginicon02,
  loginicon03,
  loginlogo,
  logo,
} from "../../imagepath";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import { Button, DatePicker, Divider, Form, notification, Upload } from "antd";
import PhoneInput from 'react-phone-number-input';
import moment from "moment";
import Select from "react-select";
import { observer } from "mobx-react-lite";
import { ArrowBack } from "@mui/icons-material";
import { MinusCircleOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useStore } from "../../../store/root-store";
import { SubmitButton } from "../../../common-components/submitButton";


const Signup = observer(() => {
  const {signingIn, signup} = useStore('');
  const [form] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const [passwordVisible1, setPasswordVisible1] = useState(false);
  const [password1, setPassword1] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [bannerUrl, setBannerUrl] = useState('');
  const [headerUrl, setHeaderUrl] = useState('');
  const [footerUrl, setFooterUrl] = useState('');
  const [certificateImages, setCertificateImages] = useState({});

  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(!passwordVisible1);
  };

  const getBase64 = async (img, fieldName) => {
    const reader = new FileReader();
    await reader.addEventListener('load', () => {
      if (fieldName?.includes('certificate')) {
        const getKey = fieldName?.split('-')[1];
        const prevValues = form.getFieldValue('certification_license');
        prevValues[getKey].logo = reader.result;
        form.setFieldsValue({
          certification_license: prevValues,
        });
        setCertificateImages({...certificateImages, [getKey]: reader.result});
      } else {
        form.setFieldsValue({
          [fieldName]: reader.result,
        });
        if (fieldName == 'logo') {
          setLogoUrl(reader.result);
        } 
        if (fieldName == 'banner') {
          setBannerUrl(reader.result);
        }
        if (fieldName == 'header') {
          setHeaderUrl(reader.result);
        }
        if (fieldName == 'footer') {
          setFooterUrl(reader.result);
        }
      }
    });
    reader.readAsDataURL(img);
    return reader.result;
  };

  const uploadButton = (fieldName) => {
    return <button
    style={{
      border: 0,
      background: 'none',
    }}
    type="button"
  >
    {<PlusOutlined />}
    <div
      style={{
        marginTop: 8,
      }}
    >
      {fieldName || 'Upload'}
    </div>
  </button>
  }
  
  return (
    <div>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="Logo" />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/admin-dashboard" style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
                          <img src={logo} alt="#" style={{height: '39px'}}/><b style={{fontSize: '26px'}}>Medix lab</b>
                        </Link>
                      </div>
                      <h2>
                        {/* {step === 2 && <ArrowBack onClick={() => setStep(1)} style={{cursor: 'pointer'}}/>}  */}
                          {step === 2 ? 'Organization details' : 'Getting Started'}</h2>
                      {/* Form */}
                      <Form form={form} initialValues={{
                      certification_license: [{}]
                    }} onFinishFailed={(val) => {
                        console.log('failled', val)
                      }} onFinish={async (values) => {
                        if (step === 1) {
                          setStep(2);
                          setUserDetails(values);
                        } else {
                          const payloadObj = {
                            "user" : {
                                  "full_name": userDetails?.first_name,
                                  // "last_name": userDetails?.last_name,
                                  "email": userDetails?.email,
                                  "gender": userDetails?.gender?.value,
                                  "address": userDetails?.address,
                                  "contact_number": userDetails?.contact_number,
                                  "date_of_birth": userDetails?.date_of_birth,
                                  "position": userDetails?.position?.value,
                                  "password": userDetails?.password
                              },
                              "organization" : {
                                  "name": values?.organization_name,
                                  "logo": values?.logo,
                                  "banner": values?.banner || '',
                                  // "lab_no": Number(values?.lab_no),
                                  "address": values?.organization_address,
                                  "contact_number": values?.organization_contact,
                                  "header": values?.header || '',
                                  "footer": values?.footer || '',
                                  "official_email": values?.organization_email
                              },
                              "certification_license": values?.certification_license?.map(z => {
                                return {
                                  "name": z?.name || '',
                                  "registration_no": z?.registration_no || '',
                                  "logo": z?.logo || '',
                                }
                              })                     
                          };
                          const res = await signup(payloadObj);
                          if (res?.status_code == 200) {
                            notification.success({message: res?.message})
                            navigate('/patientslist')
                          } else {
                            notification.error({message: 'Error occured!'})
                          }
                        }
                        
                        }}>
                        {step === 1 ? <>
                          <div className="form-group local-forms">
                          <label>
                            Full Name <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'first_name'} rules={[{ required: true, message: 'This field is required' }]}>
                            <input className="form-control" type="text" placeholder="Full name"/>
                          </Form.Item>
                        </div>
                        {/* <div className="form-group local-forms">
                          <label>
                            Last Name <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'last_name'} rules={[{ required: true, message: 'This field is required' }]}>
                            <input className="form-control" type="text" />
                          </Form.Item>
                        </div> */}
                        <div className="form-group local-forms">
                          <label>
                            Email <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'email'} rules={[{ required: true, message: 'This field is required' }, {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          }]}>
                            <input className="form-control" type="text" />
                          </Form.Item>
                        </div>
                        <div className="form-group local-forms">
                          <label>
                            Gender <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'gender'} rules={[{ required: true, message: 'This field is required' }]}>
                            <Select
                              options={[
                                { label: 'Male', value: 'Male' },
                                { label: 'Female', value: 'Female' },
                              ]}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group local-forms">
                          <label>
                            Address <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'address'} rules={[{ required: true, message: 'This field is required' }]}>
                            <input className="form-control" type="text" />
                          </Form.Item>
                        </div>
                        <div className="form-group local-forms">
                          <label>
                            Contact Number <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'contact_number'} rules={[{ required: true, message: 'This field is required' }, {max: 13, message: 'Maximum 10 digits allowed!'}, {min: 13, message: 'Minimum 10 digits allowed!'}]}>
                            <PhoneInput
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="PK"
                              countries={['PK']}
                              className="form-control disable-input-border"
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group local-forms">
                          <label>
                            DOB <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'date_of_birth'} rules={[{ required: true, message: 'This field is required' }]}getValueProps={val => {
                              return moment(val);
                            }} getValueFromEvent={dateObj => {
                              return moment(dateObj).format('YYYY-MM-DD')
                            }}>
                              <DatePicker
                                className="form-control datetimepicker"
                                suffixIcon={null}
                              />
                          </Form.Item>
                        </div>
                        <div className="form-group local-forms">
                          <label>
                            Position <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'position'} rules={[{ required: true, message: 'This field is required' }]}>
                            <Select
                              options={[
                                { label: 'Receptionist', value: 'RECEPTIONIST' },
                                { label: 'Owner', value: 'OWNER' },
                                { label: 'Manager', value: 'MANAGER' },
                                { label: 'Technician', value: 'TECHNICIAN' },
                                { label: 'Technologist', value: 'TECHNOLOGIST' },
                                { label: 'Lab supervisor', value: 'LAB_SUPERVISOR' },
                                { label: 'Lab Manager', value: 'LAB_MANAGER' },
                                { label: 'Pathologist', value: 'PATHOLOGIST' },
                              ]}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group local-forms">
                          <label>
                            Password <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'password'} rules={[{ required: true, message: 'This field is required' }]}>
                          <input
                          type={passwordVisible ? 'password' : ''}
                          className="form-control pass-input"
                          // value={password}
                          // onChange={(e) => setPassword(e.target.value)}
                        />
                        </Form.Item>
                        <span
                          className="toggle-password"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisible ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                        </span>
                        </div>
                        {/* <div className="form-group local-forms">
                          <label>
                            Confirm Password{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <input
                          type={passwordVisible1 ? 'password' : ''}
                          className="form-control pass-input"
                          value={password1}
                          onChange={(e) => setPassword1(e.target.value)}
                        />
                        <span
                          className="toggle-password"
                          onClick={togglePasswordVisibility1}
                        >
                          {passwordVisible1 ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                        </span>
                          <input
                            className="form-control pass-input-confirm"
                            type="password"
                          />
                          <span className="profile-views feather-eye-off confirm-password" />
                        </div> */}
                        </> : <>
                        {/* <div className="form-group local-forms">
                          <label>
                            Lab Number <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'lab_no'} rules={[{ required: true, message: 'This field is required' }]}>
                            <input className="form-control" type="number" />
                          </Form.Item>
                        </div> */}
                        <div className="form-group local-forms">
                          <label>
                            Organization Name <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'organization_name'} rules={[{ required: true, message: 'This field is required' }]}>
                            <input className="form-control" type="text" />
                          </Form.Item>
                        </div>
                        <div className="form-group local-forms">
                          <label>
                            Organization address <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'organization_address'} rules={[{ required: true, message: 'This field is required' }]}>
                            <input className="form-control" type="text" />
                          </Form.Item>
                        </div>
                        <div className="form-group local-forms">
                          <label>
                            Organization Contact <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'organization_contact'} rules={[{ required: true, message: 'This field is required' }, {max: 13, message: 'Maximum 10 digits allowed!'}, {min: 13, message: 'Minimum 10 digits allowed!'}]}>
                            <PhoneInput
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="PK"
                              countries={['PK']}
                              className="form-control disable-input-border"
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group local-forms">
                          <label>
                            Organization Official Email <span className="login-danger">*</span>
                          </label>
                          <Form.Item name={'organization_email'} rules={[{ required: true, message: 'This field is required' }, {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          }]}>
                            <input className="form-control" type="text" />
                          </Form.Item>
                        </div>
                        <div className="form-group local-forms">
                          <Form.Item name={'logo'} valuePropName="file" style={{ padding: '28px 20px 20px 28px', border: '2px solid rgba(46, 55, 164, 0.1)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} getValueFromEvent={(val) => {
                              return getBase64(val.file.originFileObj, 'logo');
                            }} rules={[{ required: true, message: 'This field is required' }]}>
                              <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={() => { }}
                              >
                                {logoUrl ? (
                                  <img
                                    src={logoUrl}
                                    alt="avatar"
                                    style={{
                                      height: '100%',
                                    }}
                                  />
                                ) : (
                                  uploadButton('Logo')
                                )}
                              </Upload>
                            </Form.Item>
                        </div>
                        <div className="form-group local-forms">
                          <Form.Item name={'banner'} valuePropName="file" style={{ padding: '28px 20px 20px 28px', border: '2px solid rgba(46, 55, 164, 0.1)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} getValueFromEvent={(val) => {
                              return getBase64(val.file.originFileObj, 'banner');
                            }}>
                              <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={() => { }}
                              >
                                {bannerUrl ? (
                                  <img
                                    src={bannerUrl}
                                    alt="avatar"
                                    style={{
                                      height: '100%',
                                    }}
                                  />
                                ) : (
                                  uploadButton('Banner')
                                )}
                              </Upload>
                            </Form.Item>
                        </div>
                        <div className="form-group local-forms">
                          <Form.Item name={'header'} valuePropName="file" style={{ padding: '28px 20px 20px 28px', border: '2px solid rgba(46, 55, 164, 0.1)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} getValueFromEvent={(val) => {
                              return getBase64(val.file.originFileObj, 'header');
                            }}>
                              <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={() => { }}
                              >
                                {headerUrl ? (
                                  <img
                                    src={headerUrl}
                                    alt="avatar"
                                    style={{
                                      height: '100%',
                                    }}
                                  />
                                ) : (
                                  uploadButton('Header')
                                )}
                              </Upload>
                            </Form.Item>
                        </div>
                        <div className="form-group local-forms">
                          <Form.Item name={'footer'} valuePropName="file" style={{ padding: '28px 20px 20px 28px', border: '2px solid rgba(46, 55, 164, 0.1)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} getValueFromEvent={(val) => {
                              return getBase64(val.file.originFileObj, 'footer');
                            }}>
                              <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={() => { }}
                              >
                                {footerUrl ? (
                                  <img
                                    src={footerUrl}
                                    alt="avatar"
                                    style={{
                                      height: '100%',
                                    }}
                                  />
                                ) : (
                                  uploadButton('Footer')
                                )}
                              </Upload>
                            </Form.Item>
                        </div>
                        
                        <div className="col-12">
                              <Divider orientation="left" style={{ marginTop: 0 }}>Certification license</Divider>
                            </div>
                            <Form.List name="certification_license">
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => {
                                    return <>
                                      <div className="form-group local-forms">
                                        <label>
                                          Test <span className="login-danger">*</span>
                                        </label>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'name']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'This field is required',
                                            },
                                          ]}
                                        >
                                          <input className="form-control" type="text" />
                                        </Form.Item>
                                      </div>
                                      <div className="form-group local-forms">
                                        <label>
                                          Registration Number <span className="login-danger">*</span>
                                        </label>
                                          <Form.Item {...restField} name={[name, 'registration_no']} rules={[
                                            {
                                              required: true,
                                              message: 'This field is required',
                                            },
                                          ]}>
                                            <input className="form-control" type="text" />
                                          </Form.Item>
                                      </div>
                                
                                      <div className="form-group local-forms">
                                        <label>
                                          Logo <span className="login-danger">*</span>
                                        </label>
                                        <Form.Item {...restField} name={[name, 'logo']} valuePropName="file" style={{ padding: '28px 20px 20px 28px', border: '2px solid rgba(46, 55, 164, 0.1)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} getValueFromEvent={(val) => {
                                          return getBase64(val.file.originFileObj, `certificate-${key}`);
                                        }} rules={[
                                          {
                                            required: true,
                                            message: 'This field is required',
                                          },
                                        ]}>
                                        <Upload
                                          name="avatar"
                                          listType="picture-card"
                                          className="avatar-uploader"
                                          showUploadList={false}
                                          customRequest={() => { }}
                                        >
                                          {certificateImages[key] ? (
                                            <img
                                              src={certificateImages[key]}
                                              alt="avatar"
                                              style={{
                                                height: '100%',
                                              }}
                                            />
                                          ) : (
                                            uploadButton('Logo')
                                          )}
                                        </Upload>
                                        </Form.Item>
                                      </div>
                                      {key !== 0 && <Button type="dashed" onClick={() => remove(name)} block icon={<MinusOutlined />}>
                                          Remove field
                                        </Button>}
                                        <Divider />
                                  </>
                                  })}
                                  <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                      Add Certificate
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>

                            <div className="forgotpass">
                              <div className="remember-me">
                                <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                                  {" "}
                                  I agree to the{" "}
                                  <Link to="#">&nbsp; terms of service </Link>
                                  &nbsp; and{" "}
                                  <Link to="#">&nbsp; privacy policy </Link>
                                  <input type="checkbox" name="radio" />
                                  <span className="checkmark" />
                                </label>
                              </div>
                            </div>
                        </>}
                        
                        <div className="form-group login-btn">
                          {step === 1 ? <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Next
                          </button> : 
                            <SubmitButton loading={signingIn} classnames={'btn btn-primary btn-block'} buttonText={'Sign up'}/>
                          }
                        </div>
                      </Form>
                      {/* /Form */}
                      <div className="next-sign">
                        <p className="account-subtitle">
                          Already have account? <Link to="/login">Login</Link>
                        </p>
                        {/* Social Login */}
                        <div className="social-login">
                          <Link to="#">
                            <img src={loginicon01}alt="#"/>
                          </Link>
                          <Link to="#">
                            <img src={loginicon02}alt="#"/>
                          </Link>
                          <Link to="#">
                            <img src={loginicon03}alt="#"/>
                          </Link>
                        </div>
                        {/* /Social Login */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Signup;
