/* eslint-disable no-unused-vars */
import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { DatePicker} from "antd";
import { Link } from 'react-router-dom';
import { PageLayout } from "../../common-components/pageLayout";
const EditLeave = () => {
  const onChange = (date, dateString) => {
    // console.log(date, dateString);
  };

  return (
    <>
      <div>
        <Header />
        <Sidebar />
        <PageLayout>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Staffs </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">edit Leave</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Edit Leave</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Leave Type <span className="login-danger">*</span>
                            </label>
                            <select className="form-control select">
                              <option>Select Leave Type</option>
                              <option>Medical Leave</option>
                              <option>Casual Leave</option>
                              <option>Loss of Pay</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Number of Days{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              defaultValue={2}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms cal-icon">
                            <label>
                              From <span className="login-danger">*</span>
                            </label>
                            <DatePicker
                              className="form-control datetimepicker"
                              onChange={onChange}
                              suffixIcon={null}
                            />
                            {/* <input
                      className="form-control datetimepicker"
                      type="text"
                      defaultValue="26-11-2022"
                    /> */}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms cal-icon">
                            <label>
                              To <span className="login-danger">*</span>
                            </label>
                            <DatePicker className="form-control datetimepicker" onChange={onChange} 
                             suffixIcon={null}
                             />
                            {/* <input
                              className="form-control datetimepicker"
                              type="text"
                              defaultValue="26-11-2022"
                            /> */}
                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <div className="form-group local-forms">
                            <label>
                              Leave Reason{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              rows={3}
                              cols={30}
                              defaultValue={"Going to hospital"}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <input
                              className="form-control"
                              type="text"
                              readOnly=""
                              defaultValue="Remainig Leaves: 12"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </PageLayout>
      </div>
    </>
  );
};

export default EditLeave;
