import { toJS } from 'mobx';
import {flow, types} from 'mobx-state-tree';
import api from '../api';

export const DepartmentsStore = types.model({
    departmentListLoading: types.boolean,
    departmentList: types.array(types.model({
      created_at: types.maybeNull(types.string),
      id: types.maybeNull(types.number),
      head: types.maybeNull(types.number),
      is_active: types.maybeNull(types.boolean),
      name: types.maybeNull(types.string),
      description: types.maybeNull(types.string),
      updated_at: types.maybeNull(types.string),
      source: types.maybeNull(types.string),
      updated_department: types.maybeNull(types.model({
        name: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
      }))
  })),
}).views(self => ({
    get getDepartmentsList() {
      return toJS(self.departmentList);
    },
  })).actions(self => {
    const fetchDepartmentsList = flow(function* () {
      self.departmentListLoading = true;
      try {
        const response = yield api.get(`/labs/department_list/`);
        self.departmentList = response?.data?.data;
        self.departmentListLoading = false;
      } catch (e) {
        return e?.response?.data;
      }
    });
    const createDepartmentRecord = flow(function* (payload) {
      self.creatingRecord = true;
      try {
        const response = yield api.post(`/labs/create_department/`, payload);
        self.creatingRecord = false;
        return response?.data;
      } catch (e) {
        self.creatingRecord = false;
        return e?.response?.data;
      }
    });
    const updateDepartmentRecord = flow(function* (id, payload) {
      self.updatingRecord = true;
      try {
        const response = yield api.put(`/labs/update_department/${id}/`, payload);
        console.log('response', response)
        self.updatingRecord = false;
        return response?.data;
      } catch (e) {
        self.updatingRecord = false;
        return e?.response?.data;
      }
    });
    const deleteDepartment = flow(function* (id) {
      try {
        const response = yield api.delete(`/labs/delete_department/${id}/`);
        return response?.data;
      } catch (e) {
        return e?.response?.data;
      }
    });
  return {
    fetchDepartmentsList,
    createDepartmentRecord,
    updateDepartmentRecord,
    deleteDepartment,
  };
});


export function initDepartmentsStore() {
    return DepartmentsStore.create({
        departmentList: [],
        departmentListLoading: true,
        creatingRecord: false,
        updatingRecord: false,
    });
}
