/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { doctor03, imguser } from "../../imagepath";
import { Button, DatePicker, Divider, Form, notification, Upload } from "antd";
import Select from "react-select";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store/root-store";
import PhoneInput from 'react-phone-number-input';
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { PageLayout } from "../../../common-components/pageLayout";

const EditProfile = observer(() => {
  const {getUserData, updateProfile} = useStore('');
  const [form] = Form.useForm();
  const [selectedOption, setSelectedOption] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [bannerUrl, setBannerUrl] = useState('');
  const [headerUrl, setHeaderUrl] = useState('');
  const [footerUrl, setFooterUrl] = useState('');
  const [certificateImages, setCertificateImages] = useState({});
  const [gender, setGender] = useState([
    { value: 1, label: "Select Gender" },
    { value: 2, label: "Male" },
    { value: 3, label: "Female" },
  ]);
  const onChange = (date, dateString) => {
    // console.log(date, dateString);
  };

  const getBase64 = async (img, fieldName) => {
    const reader = new FileReader();
    await reader.addEventListener('load', () => {
      if (fieldName?.includes('certificate')) {
        const getKey = fieldName?.split('-')[1];
        const prevValues = form.getFieldValue('certification_license');
        prevValues[getKey].logo = reader.result;
        form.setFieldsValue({
          certification_license: prevValues,
        });
        setCertificateImages({...certificateImages, [getKey]: reader.result});
      } else {
        form.setFieldsValue({
          [fieldName]: reader.result,
        });
        if (fieldName == 'logo') {
          setLogoUrl(reader.result);
        } 
        if (fieldName == 'banner') {
          setBannerUrl(reader.result);
        }
        if (fieldName == 'header') {
          setHeaderUrl(reader.result);
        }
        if (fieldName == 'footer') {
          setFooterUrl(reader.result);
        }
      }
    });
    reader.readAsDataURL(img);
    return reader.result;
  };

  const uploadButton = (fieldName) => {
    return <button
    style={{
      border: 0,
      background: 'none',
    }}
    type="button"
  >
    {<PlusOutlined />}
    <div
      style={{
        marginTop: 8,
      }}
    >
      {fieldName || 'Upload'}
    </div>
  </button>
  }


  const updateDataInFields = (updatedData) => {
    if (updatedData?.company_logo) {
      setLogoUrl(updatedData?.company_logo);
    }
    if (updatedData?.header) {
      setHeaderUrl(updatedData?.header);
    }
    if (updatedData?.footer) {
      setFooterUrl(updatedData?.footer);
    }

    form.setFieldsValue({
      organization_address: updatedData?.address,
      organization_email: updatedData?.official_email,
      logo: updatedData?.company_logo,
      header: updatedData?.header,
      footer: updatedData?.footer,
      certification_license: updatedData?.certifications?.length ? updatedData?.certifications?.map(z => {
        return {
          name: z?.name || '',
          registration_no: z?.registration_no || '',
          logo: z?.logo || '',
        }
      }) : {}
    })
  }

  useEffect(() => {
    if (getUserData) {
      updateDataInFields(getUserData)
    }
  }, [getUserData])

  return (
    <>
      <Header />
      <Sidebar />
      <>
        <PageLayout>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Profile</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
              <div className="card-box">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-view">
                      <div className="profile-img-wrap" style={{height: '130px', width: '130px'}}>
                        <div className="profile-img" style={{height: '130px', width: '130px'}}>
                          <Link to="#"><img className="avatar" style={{width: '130px', height: '130px', lineHeight: '130px'}} src={doctor03} alt="#" /></Link>
                        </div>
                      </div>
                      <div className="profile-basic">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="profile-info-left">
                              <h3 className="user-name m-t-0 mb-0">{getUserData?.full_name || '-'}</h3>
                              <small className="text-muted">{getUserData?.email || '-'}</small>
                              <div className="staff-id">User ID : {getUserData?.id || '-'}</div>
                              {/* <div className="staff-msg"><Link to="/chat" className="btn btn-primary">Send Message</Link></div> */}
                            </div>
                          </div>
                          <div className="col-md-7">
                            <ul className="personal-info">
                              <li>
                                <span className="title">Phone:</span>
                                <span className="text"><Link to>{getUserData?.contact_number || '-'}</Link></span>
                              </li>
                              <li>
                                <span className="title">Official Email:</span>
                                <span className="text"><Link to>{getUserData?.official_email || '-'}</Link></span>
                              </li>
                              <li>
                                <span className="title">Company name:</span>
                                <span className="text">{getUserData?.company_name || '-'}</span>
                              </li>
                              <li>
                                <span className="title">Address:</span>
                                <span className="text">{getUserData?.address || '-'}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card-box">
              <h3 className="card-title">Basic Informations</h3>
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-img-wrap">
                    <img className="inline-block" src={imguser} alt="user" />
                    <div className="fileupload btn">
                      <span className="btn-text">edit</span>
                      <input className="upload" type="file" />
                    </div>
                  </div>
                  <div className="profile-basic">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group local-forms">
                          <label className="focus-label">First Name</label>
                          <input
                            type="text"
                            className="form-control floating"
                            defaultValue="John"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group local-forms">
                          <label className="focus-label">Last Name</label>
                          <input
                            type="text"
                            className="form-control floating"
                            defaultValue="Doe"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group local-forms ">
                          <label className="focus-label">Birth Date</label>
                          <div className="cal-icon">
                          <DatePicker
                            className="form-control datetimepicker"
                            onChange={onChange}
                            suffixIcon={null}
                          />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group local-forms">
                          <label className="focus-label">Gendar</label>
                          <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={gender}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <Form form={form} initialValues={{
                      certification_license: [{}]
                    }} onFinish={async (values) => {
                      const payloadObj = {
                        organization: {
                          address: values?.organization_address,
                          official_email: values?.organization_email,
                          contact_number: values?.organization_contact,
                          logo: values?.logo,
                          banner: values?.banner,
                          header: values?.header,
                          footer: values?.footer,
                        },
                        certification_license: values?.certification_license?.length ? values?.certification_license?.map(z => {
                          return {
                            name: z?.name || '',
                            registration_no: z?.registration_no || '',
                            logo: z?.logo || '',
                          }
                        }) : {}
                      }
                      const res = await updateProfile(payloadObj);
                          if (res?.status_code == 200) {
                            notification.success({message: res?.message})
                          } else {
                            notification.error({message: 'Error occured!'})
                          }
                    }}>
              <div className="card-box">
                <h3 className="card-title">Organization details</h3>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group local-forms">
                      <label>
                        Organization address <span className="login-danger">*</span>
                      </label>
                      <Form.Item name={'organization_address'} rules={[{ required: true, message: 'This field is required' }]}>
                        <input className="form-control" type="text" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label>
                        Organization Contact <span className="login-danger">*</span>
                      </label>
                      <Form.Item name={'organization_contact'} rules={[{ required: true, message: 'This field is required' }, {max: 13, message: 'Maximum 10 digits allowed!'}, {min: 13, message: 'Minimum 10 digits allowed!'}]}>
                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="PK"
                          countries={['PK']}
                          className="form-control disable-input-border"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label>
                        Organization Official Email <span className="login-danger">*</span>
                      </label>
                      <Form.Item name={'organization_email'} rules={[{ required: true, message: 'This field is required' }, {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      }]}>
                        <input className="form-control" type="text" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <Form.Item name={'logo'} valuePropName="file" style={{ padding: '28px 20px 20px 28px', border: '2px solid rgba(46, 55, 164, 0.1)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} getValueFromEvent={(val) => {
                          return getBase64(val.file.originFileObj, 'logo');
                        }} rules={[{ required: true, message: 'This field is required' }]}>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={() => { }}
                          >
                            {logoUrl ? (
                              <img
                                src={logoUrl}
                                alt="avatar"
                                style={{
                                  height: '100%',
                                }}
                              />
                            ) : (
                              uploadButton('Logo')
                            )}
                          </Upload>
                        </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <Form.Item name={'banner'} valuePropName="file" style={{ padding: '28px 20px 20px 28px', border: '2px solid rgba(46, 55, 164, 0.1)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} getValueFromEvent={(val) => {
                          return getBase64(val.file.originFileObj, 'banner');
                        }}>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={() => { }}
                          >
                            {bannerUrl ? (
                              <img
                                src={bannerUrl}
                                alt="avatar"
                                style={{
                                  height: '100%',
                                }}
                              />
                            ) : (
                              uploadButton('Banner')
                            )}
                          </Upload>
                        </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <Form.Item name={'header'} valuePropName="file" style={{ padding: '28px 20px 20px 28px', border: '2px solid rgba(46, 55, 164, 0.1)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} getValueFromEvent={(val) => {
                          return getBase64(val.file.originFileObj, 'header');
                        }}>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={() => { }}
                          >
                            {headerUrl ? (
                              <img
                                src={headerUrl}
                                alt="avatar"
                                style={{
                                  height: '100%',
                                }}
                              />
                            ) : (
                              uploadButton('Header')
                            )}
                          </Upload>
                        </Form.Item>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <Form.Item name={'footer'} valuePropName="file" style={{ padding: '28px 20px 20px 28px', border: '2px solid rgba(46, 55, 164, 0.1)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} getValueFromEvent={(val) => {
                          return getBase64(val.file.originFileObj, 'footer');
                        }}>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={() => { }}
                          >
                            {footerUrl ? (
                              <img
                                src={footerUrl}
                                alt="avatar"
                                style={{
                                  height: '100%',
                                }}
                              />
                            ) : (
                              uploadButton('Footer')
                            )}
                          </Upload>
                        </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <h3 className="card-title">Certification license details</h3>
                <div className="row">
                  <Form.List name="certification_license">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => {
                          return <>
                            <div className="col-md-6">
                              <div className="form-group local-forms">
                                <label>
                                  Test <span className="login-danger">*</span>
                                </label>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'name']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                  ]}
                                >
                                  <input className="form-control" type="text" />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group local-forms">
                                <label>
                                  Registration Number <span className="login-danger">*</span>
                                </label>
                                  <Form.Item {...restField} name={[name, 'registration_no']} rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                  ]}>
                                    <input className="form-control" type="text" />
                                  </Form.Item>
                              </div>
                            </div>
                      
                            <div className="form-group local-forms" style={{marginBottom: 0}}>
                              <label>
                                Logo <span className="login-danger">*</span>
                              </label>
                              <Form.Item {...restField} name={[name, 'logo']} valuePropName="file" style={{ padding: '28px 20px 20px 28px', border: '2px solid rgba(46, 55, 164, 0.1)', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} getValueFromEvent={(val) => {
                                return getBase64(val.file.originFileObj, `certificate-${key}`);
                              }} rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}>
                              <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={() => { }}
                              >
                                {certificateImages[key] ? (
                                  <img
                                    src={certificateImages[key]}
                                    alt="avatar"
                                    style={{
                                      height: '100%',
                                    }}
                                  />
                                ) : (
                                  uploadButton('Logo')
                                )}
                              </Upload>
                              </Form.Item>
                            </div>
                            {key !== 0 && <div className="col-md-12">
                              <div className="btn btn-primary" style={{marginBottom: '15px'}} onClick={() => remove(name)}>
                                <MinusOutlined />&nbsp;Remove field
                              </div>
                              </div>}
                            <Divider />
                        </>
                        })}
                        <Form.Item>
                        <div className="add-more">
                          <span className="btn btn-primary" onClick={() => add()}>
                            <i className="fa fa-plus" /> Add More Certification license
                          </span>
                        </div>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </div>
              <div className="text-center m-t-20">
                <button className="btn btn-primary submit-btn mb-4" type="submit">
                  Save
                </button>
              </div>
            </Form>
          </PageLayout>
      </>
    </>
  );
});

export default EditProfile;
