import { toJS } from 'mobx';
import {flow, types} from 'mobx-state-tree';
import api from '../api';

export const PanelsStore = types.model({
  panelsListLoading: types.boolean,
  panelsList: types.array(types.model({
      contact: types.maybeNull(types.string),
      created_at: types.maybeNull(types.string),
      designation: types.maybeNull(types.string),
      id: types.maybeNull(types.number),
      is_active: types.maybeNull(types.boolean),
      name: types.maybeNull(types.string),
      responsible_person_name: types.maybeNull(types.string),
      updated_at: types.maybeNull(types.string),
      user: types.maybeNull(types.number),
  })),
}).views(self => ({
    get getPanelsList() {
      return toJS(self.panelsList);
    },
  })).actions(self => {
    const fetchPanelsList = flow(function* () {
      self.panelsListLoading = true;
      try {
        const response = yield api.get(`/labs/panel_list/`);
        self.panelsList = response?.data?.data;
        self.panelsListLoading = false;
      } catch (e) {
        return Promise.reject(e);
      }
    });
    const createPanelRecord = flow(function* (payload) {
      self.creatingRecord = true;
      try {
        const response = yield api.post(`/labs/create_panel/`, payload);
        self.creatingRecord = false;
        return response?.data;
      } catch (e) {
        self.creatingRecord = false;
        return Promise.reject(e);
      }
    });
    const updatePanelRecord = flow(function* (id, payload) {
      self.updatingRecord = true;
      try {
        const response = yield api.put(`/labs/update_panel/${id}/`, payload);
        console.log('response', response)
        self.updatingRecord = false;
        return response?.data;
      } catch (e) {
        self.updatingRecord = false;
        return Promise.reject(e);
      }
    });
    const deletePanel = flow(function* (id) {
      try {
        const response = yield api.delete(`/labs/delete_panel/${id}/`);
        return response?.data;
      } catch (e) {
        return Promise.reject(e);
      }
    });
  return {
    fetchPanelsList,
    createPanelRecord,
    updatePanelRecord,
    deletePanel,
  };
});


export function initPanelsStore() {
    return PanelsStore.create({
        panelsList: [],
        panelsListLoading: true,
        creatingRecord: false,
        updatingRecord: false,
    });
}
