import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { useStore } from "../store/root-store";

export const PageLayout = observer(({ children }) => {
    const {getUserData} = useStore('');
    return <>
        <div className="page-wrapper">
            <div className="content">
                {(getUserData?.profile_completed === false) && <div className="alert alert-warning alert-dismissible fade show" role="alert">
                    <strong>Warning!</strong> Your profile setup was not completed. <Link to="/edit-profile" className="alert-link">complete my profile</Link>.
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true"> </span>
                    </button>
                </div>}
                {children}
            </div>
        </div>
    </>
})