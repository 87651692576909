import jsPDF from "jspdf";
import { resetStore } from "../store/root-store";
import { BASE_URL } from "../api/constants";
import moment from "moment";

export const onLogout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('username');
  resetStore();
};

export async function generatePatientReceipt(patientRecord, user) {
  // console.log('patientRecord', patientRecord)

  var doc = new jsPDF();
  const logoUrl = user?.company_logo || `/favicon.png`;
  doc.addImage(logoUrl, "PNG", 8, 8, 18, 18);
  const date = `Date: ${new Date().toLocaleDateString()}`;
  const companyName = user?.company_name || 'hims labs';
  const companyAddress = "123 Company Address, City, Country";
  const patientName = `${patientRecord?.title || ''}${patientRecord?.first_name || ''}${patientRecord?.middle_name || ''} ${patientRecord?.last_name || ''}`;
  const items = patientRecord?.patient_tests || [];
  const total = Number(patientRecord?.test_detail?.total_amount);

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const marginLeft = 8;
  const marginRight = 8;
  let headerY = 16;

  if (user?.header) {
    const headerImage = doc.getImageProperties(user.header);
    doc.addImage(user.header, "PNG", marginLeft, headerY-8, pageWidth - marginLeft - marginRight, headerImage.height/10);
    headerY += headerImage.height/10-6;
  } else {
    doc.addImage(logoUrl, "PNG", marginLeft, 8, 18, 18);
    doc.setFontSize(22);
    doc.text(companyName, 28, headerY);
    doc.setFontSize(12);
    headerY += 8;
    doc.text(companyAddress, 28, headerY);
    headerY += 4;
    doc.setDrawColor('#121212');
    doc.setFontSize(12);
    const textWidth = doc.getTextWidth(date);
    doc.text(date, pageWidth - textWidth - marginRight, 16);
  }

  // doc.setFontSize(22);
  // doc.text(companyName, 28, headerY);
  // doc.setFontSize(12);
  // headerY += 8;
  // doc.text(companyAddress, 28, headerY);
  // headerY += 4;


  // doc.setFontSize(12);
  // const textWidth = doc.getTextWidth(date);
  // doc.text(date, pageWidth - textWidth - marginRight, 16);

  doc.setFontSize(10);
  doc.setFillColor('#e1dfda');
  doc.rect(marginLeft, headerY, pageWidth - marginLeft - marginRight, 6, 'F');
  doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
  headerY += 4;
  const patientNumberLabel = `Patient Number: `;
  doc.text(patientNumberLabel, marginLeft, headerY);
  const patientNumberLabelWidth = doc.getTextWidth(patientNumberLabel);
  doc.setFont("helvetica", "bold");
  doc.text(`${patientRecord?.mr_no}`, patientNumberLabelWidth + marginLeft, headerY);
  doc.setFont("helvetica", "normal");
  headerY += 2;
  doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
  headerY += 5;
  const secondColumnStart = 52;
  const thirdColumnStart = 104;
  doc.text(`Patient Name:`, marginLeft, headerY);
  doc.text(`Age / Sex:`, marginLeft + secondColumnStart, headerY);
  doc.text(`MR No:`, marginLeft + thirdColumnStart, headerY);
  doc.addImage("/qr_code_dummy.png", "PNG", pageWidth - marginRight - 28, headerY - 3, 28, 28);
  headerY += 5;
  doc.setFont("helvetica", "bold");
  doc.text(`${patientName}`, marginLeft, headerY);
  doc.text(`${patientRecord?.age} / ${patientRecord?.gender}`, marginLeft + secondColumnStart, headerY);
  doc.text(`${patientRecord?.mr_no}`, marginLeft + thirdColumnStart, headerY);
  doc.setFont("helvetica", "normal");
  headerY += 5;
  doc.text(`Phone:`, marginLeft, headerY);
  doc.text(`S/O D/O W/O:`, marginLeft + secondColumnStart, headerY);
  doc.text(`Registered at:`, marginLeft + thirdColumnStart, headerY);
  headerY += 5;
  doc.setFont("helvetica", "bold");
  doc.text(`${patientRecord?.contact_number}`, marginLeft, headerY);
  doc.text(`${patientRecord?.last_name}`, marginLeft + secondColumnStart, headerY);
  doc.text(`${patientRecord?.registered_at}`, marginLeft + thirdColumnStart, headerY);
  doc.setFont("helvetica", "normal");
  headerY += 5;
  doc.text(`Collection Time:`, marginLeft, headerY);
  doc.text(`Specimen Collection Type:`, marginLeft + secondColumnStart, headerY);
  doc.text(`Referred By:`, marginLeft + thirdColumnStart, headerY);
  headerY += 5;
  doc.setFont("helvetica", "bold");
  doc.text(`${patientRecord?.mr_no}`, marginLeft, headerY);
  doc.text(`${patientRecord?.mr_no}`, marginLeft + secondColumnStart, headerY);
  doc.text(`${patientRecord?.mr_no}`, marginLeft + thirdColumnStart, headerY);
  doc.setFont("helvetica", "normal");

  headerY += 3;
  doc.setFillColor('#e1dfda');
  doc.rect(marginLeft, headerY, pageWidth - marginLeft - marginRight, 7, 'F');
  doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
  headerY += 5;
  doc.setFontSize(12);
  const patientNumberLabel2 = `Patient Bill`;
  doc.setFont("helvetica", "bold");
  doc.text(patientNumberLabel2, marginLeft + 85, headerY);
  doc.setFont("helvetica", "normal");
  headerY += 2;
  doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
  doc.setFillColor('#e1dfda');
  doc.rect(marginLeft, headerY, pageWidth - marginLeft - marginRight, 7, 'F');
  doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
  headerY += 5;
  doc.setFont("helvetica", "bold");
  doc.text(`Sr. No.`, marginLeft + 2, headerY);
  const priceTextWidth = doc.getTextWidth("Price");
  doc.text("Test Name", marginLeft + 25, headerY);
  doc.text("Reporting Time", marginLeft + 110, headerY);
  doc.text("Price", pageWidth - priceTextWidth - marginRight - 1, headerY);
  doc.setFont("helvetica", "normal");
  headerY += 2;
  doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
  headerY += 5;
  doc.setFontSize(11);
  items.forEach((item, index) => {
    const dataObj = (item?.tests?.source === 'platform' && item?.tests?.updated_test?.name) ? item?.tests?.updated_test : item?.tests;
    const priceVal = Number(dataObj?.price || 0).toFixed(2) || '';
    const priceValTextWidth = doc.getTextWidth(priceVal);
    doc.text(`${index + 1}`, marginLeft + 2, headerY);
    doc.text(dataObj?.name || '', marginLeft + 25, headerY);
    doc.text(moment(dataObj?.reporting_time)?.format('DD-MMM-YYYY HH:mm A'), marginLeft + 110, headerY);
    doc.text(priceVal, pageWidth - priceValTextWidth - marginRight, headerY);
    headerY += 6;
  });

  doc.setFontSize(11);
  headerY -= 2;
  doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
  headerY += 6;
  doc.text("Total", marginLeft + 100, headerY);
  doc.setFont("helvetica", "bold");
  const totalValTextWidth = doc.getTextWidth(`${total.toFixed(2)}`);
  doc.text(`${total.toFixed(2)}`, pageWidth - totalValTextWidth - marginRight, headerY);
  doc.setFont("helvetica", "normal");
  headerY += 2;
  doc.line(marginLeft + 100, headerY, pageWidth - marginRight, headerY);
  headerY += 5;
  doc.text("Discount", marginLeft + 100, headerY);
  doc.setFont("helvetica", "bold");
  const discountValTextWidth = doc.getTextWidth(`${patientRecord?.test_detail?.discount?.toFixed(2) || ''}`);
  doc.text(`${patientRecord?.test_detail?.discount?.toFixed(2) || ''}`, pageWidth - discountValTextWidth - marginRight, headerY);
  doc.setFont("helvetica", "normal");
  headerY += 2;
  doc.line(marginLeft + 100, headerY, pageWidth - marginRight, headerY);
  headerY += 5;
  doc.text("Payable", marginLeft + 100, headerY);
  doc.setFont("helvetica", "bold");
  const payableAmount = Number(total - patientRecord?.test_detail?.discount);
  const payableValTextWidth = doc.getTextWidth(`${payableAmount?.toFixed(2)}`);
  doc.text(`${payableAmount.toFixed(2)}`, pageWidth - payableValTextWidth - marginRight, headerY);
  doc.setFont("helvetica", "normal");
  headerY += 2;
  doc.line(marginLeft + 100, headerY, pageWidth - marginRight, headerY);
  headerY += 5;
  doc.text("Paid", marginLeft + 100, headerY);
  doc.setFont("helvetica", "bold");
  const paidAmount = Number(patientRecord?.test_detail?.paid_amount);
  const paidValTextWidth = doc.getTextWidth(`${paidAmount?.toFixed(2)}`);
  doc.text(`${paidAmount?.toFixed(2)}`, pageWidth - paidValTextWidth - marginRight, headerY);
  doc.setFont("helvetica", "normal");
  headerY += 2;
  doc.line(marginLeft + 100, headerY, pageWidth - marginRight, headerY);
  headerY += 5;
  doc.text("Due", marginLeft + 100, headerY);
  doc.setFont("helvetica", "bold");
  const dueValTextWidth = doc.getTextWidth(`${Number(payableAmount - paidAmount)?.toFixed(2)}`);
  doc.text(`${Number(payableAmount - paidAmount)?.toFixed(2)}`, pageWidth - dueValTextWidth - marginRight, headerY);
  doc.setFont("helvetica", "normal");
  headerY += 2;
  doc.line(marginLeft + 100, headerY, pageWidth - marginRight, headerY);
  const addBoldText = (text, xAxis, yAxis) => {
    doc.setFont("helvetica", "bold");
    doc.text(text, xAxis, yAxis);
    doc.setFont("helvetica", "normal");
  }

  const addBoldTextRightAlign = (text, xAxis, yAxis) => {
    doc.setFont("helvetica", "bold");
    const textWidth = doc.getTextWidth(text);
    doc.text(text, xAxis - textWidth, yAxis);
    doc.setFont("helvetica", "normal");
  }

  if (user?.footer) {
    const footerImage = doc.getImageProperties(user.footer);
    headerY = pageHeight - footerImage.height/10 - 11;
    doc.addImage(user.footer, "PNG", marginLeft, headerY, pageWidth - marginLeft - marginRight, 31);
    headerY += 31;
    doc.setFontSize(10);
  } else {
    headerY = pageHeight - 44;
    addBoldText("VIEW LAB REPORTS ONLINE:", marginLeft, headerY);
    headerY += 5;
    addBoldText("To view report online, scan the above QR code", marginLeft, headerY);
    headerY += 5;
    addBoldText("OR", marginLeft, headerY);
    headerY += 5;
    addBoldText("This report can be verified online at https://hims-frontend.vercel.app/", marginLeft, headerY);
    headerY += 5;
    addBoldText("Test reference number: 34546", marginLeft, headerY);
    addBoldTextRightAlign("Patient Id: 34546", pageWidth - marginRight, headerY);
    headerY += 4;
    doc.setFontSize(10);
    doc.setTextColor('#5e6163')
    addBoldText("Electronically verified receipt, no signature(s) required.", marginLeft + 50, headerY);
    doc.setTextColor('#121212')
    headerY += 2;
    doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
    headerY += 1;
    const addSocialLinksPDF = (linksList) => {
      doc.setFont("helvetica", "bold");
      let leftSpace = marginLeft;
      linksList?.forEach(link => {
        doc.addImage(link.image, "PNG", leftSpace, headerY, 4, 4);
        leftSpace += 5;
        doc.text(link?.text, leftSpace, headerY + 3);
        const checkTextWidth = doc.getTextWidth(link?.text);
        leftSpace += checkTextWidth + 2;
      });
      doc.setFont("helvetica", "normal");
    };
    addSocialLinksPDF([
      { image: logoUrl, text: "220A Garden Block Garden Town Lahore." },
      { image: logoUrl, text: "03214146093" },
      { image: logoUrl, text: "03213404554" },
      { image: logoUrl, text: "hims-frontend.vercel.app" },
    ])
    headerY += 5;
    doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
  }
  headerY += 5;
  addBoldText(`Printing Time: ${moment()?.format('DD-MMM-YYYY HH:mm A')}`, marginLeft, headerY);
  addBoldTextRightAlign("Generated by: MedixLab", pageWidth - marginRight, headerY);
  // Download the PDF
  // doc.save("receipt.pdf");
  const pdfOutput = doc.output('blob');
  const pdfUrl = URL.createObjectURL(pdfOutput);
  window.open(pdfUrl, '_blank');
};


export async function generateTestResultsPdf(patientRecord, user) {
  // console.log('patientRecord', patientRecord)

  var doc = new jsPDF();
  const logoUrl = user?.company_logo || `/favicon.png`;
  const date = `Date: ${new Date().toLocaleDateString()}`;
  const companyName = user?.company_name || 'hims labs';
  const companyAddress = "123 Company Address, City, Country";
  const patientName = `${patientRecord?.title || ''}${patientRecord?.first_name || ''}${patientRecord?.middle_name || ''} ${patientRecord?.last_name || ''}`;
  const records = patientRecord?.patient_tests || [];

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const marginLeft = 8;
  const marginRight = 8;
  // let headerY = 16;

  const addBoldText = (text, xAxis, yAxis) => {
    doc.setFont("helvetica", "bold");
    doc.text(text, xAxis, yAxis);
    doc.setFont("helvetica", "normal");
  }

  const addBoldTextRightAlign = (text, xAxis, yAxis) => {
    doc.setFont("helvetica", "bold");
    const textWidth = doc.getTextWidth(text);
    doc.text(text, xAxis - textWidth, yAxis);
    doc.setFont("helvetica", "normal");
  }

  const addHeader = (yMain) => {
    let headerY = yMain;
    if (user?.header) {
      const headerImage = doc.getImageProperties(user.header);
      doc.addImage(user.header, "PNG", marginLeft, headerY-8, pageWidth - marginLeft - marginRight, headerImage.height/10);
      headerY += headerImage.height/10-6;
    } else {
      doc.addImage(logoUrl, "PNG", marginLeft, 8, 18, 18);
      doc.setFontSize(22);
      doc.text(companyName, 28, headerY);
      doc.setFontSize(12);
      headerY += 8;
      doc.text(companyAddress, 28, headerY);
      headerY += 4;
      doc.setDrawColor('#121212');
      doc.setFontSize(12);
      const textWidth = doc.getTextWidth(date);
      doc.text(date, pageWidth - textWidth - marginRight, 16);
    }

    doc.setFontSize(10);
    doc.setFillColor('#e1dfda');
    doc.rect(marginLeft, headerY, pageWidth - marginLeft - marginRight, 6, 'F');
    doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
    headerY += 4;
    const patientNumberLabel = `Patient Number: `;
    doc.text(patientNumberLabel, marginLeft, headerY);
    const patientNumberLabelWidth = doc.getTextWidth(patientNumberLabel);

    addBoldText(`${patientRecord?.mr_no}`, patientNumberLabelWidth + marginLeft, headerY)

    headerY += 2;
    doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
    headerY += 5;
    const secondColumnStart = 52;
    const thirdColumnStart = 104;
    doc.text(`Patient Name:`, marginLeft, headerY);
    doc.text(`Age / Sex:`, marginLeft + secondColumnStart, headerY);
    doc.text(`MR No:`, marginLeft + thirdColumnStart, headerY);
    doc.addImage("/qr_code_dummy.png", "PNG", pageWidth - marginRight - 28, headerY - 3, 28, 28);
    headerY += 5;
    addBoldText(`${patientName}`, marginLeft, headerY);
    addBoldText(`${patientRecord?.age} / ${patientRecord?.gender}`, marginLeft + secondColumnStart, headerY);
    addBoldText(`${patientRecord?.mr_no}`, marginLeft + thirdColumnStart, headerY);
    headerY += 5;
    doc.text(`Phone:`, marginLeft, headerY);
    doc.text(`S/O D/O W/O:`, marginLeft + secondColumnStart, headerY);
    doc.text(`Registered at:`, marginLeft + thirdColumnStart, headerY);
    headerY += 5;
    addBoldText(`${patientRecord?.contact_number}`, marginLeft, headerY);
    addBoldText(`${patientRecord?.last_name}`, marginLeft + secondColumnStart, headerY);
    addBoldText(`${patientRecord?.registered_at}`, marginLeft + thirdColumnStart, headerY);
    headerY += 5;
    doc.text(`Collection Time:`, marginLeft, headerY);
    doc.text(`Specimen Collection Type:`, marginLeft + secondColumnStart, headerY);
    doc.text(`Referred By:`, marginLeft + thirdColumnStart, headerY);
    headerY += 5;
    addBoldText(`${patientRecord?.mr_no}`, marginLeft, headerY);
    addBoldText(`${patientRecord?.mr_no}`, marginLeft + secondColumnStart, headerY);
    addBoldText(`${patientRecord?.mr_no}`, marginLeft + thirdColumnStart, headerY);
    headerY += 2;
    doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
    headerY += 12;
    return headerY;
  }
  // addHeader();

  const addContent = (yMain, item) => {
    let headerY = yMain;
    const showPrevResult = item?.tests?.result?.previous_result ? item?.tests?.result : null;
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    const dataObj = (item?.tests?.source === 'platform' && item?.tests?.updated_test?.name) ? item?.tests?.updated_test : item?.tests;
    doc.text(item?.tests?.test_type === 'Profiling' ? dataObj?.name || '' : (item?.tests?.department?.name || 'Name of Department'), marginLeft, headerY);
    doc.setFontSize(10);
    // doc.addImage("/bar_code_dummy.png", "PNG", pageWidth - marginRight - 28, headerY - 6, 26, 9);
    if (showPrevResult) {
      doc.rect(pageWidth - marginRight - 65, headerY-5, 35, 8);
      doc.text(`${showPrevResult?.previous_date ? showPrevResult?.previous_date?.split(' ')[0] : ''}`, pageWidth - marginRight - 60, headerY);
    }
    if (item?.tests?.result?.current_date) {
      doc.rect(pageWidth - marginRight - 30, headerY-5, 30, 8);
      doc.text(`${item?.tests?.result?.current_date ? item?.tests?.result?.current_date?.split(' ')[0] : ''}`, pageWidth - marginRight - 25, headerY);
    }
    headerY += 3;
    doc.setFontSize(10);
    doc.setFillColor('#e1dfda');
    doc.rect(marginLeft, headerY, pageWidth - marginLeft - marginRight, 7, 'F');
    doc.rect(marginLeft, headerY, pageWidth - marginLeft - marginRight, 7);
    headerY += 5;
    addBoldText(`Test`, marginLeft + 2, headerY);
    addBoldText("Normal Range", marginLeft + 50, headerY);
    addBoldText("Unit", marginLeft + 100, headerY);
    if (showPrevResult) {
      addBoldText("Prev. Result", marginLeft + 130, headerY);
    }
    addBoldTextRightAlign("Result", pageWidth - marginRight - 1, headerY);
    headerY += 2;
    headerY += 5;
    // items.forEach(item => {
      if (item?.tests?.test_type === 'Profiling') {
    //     doc.setFontSize(11);
    //     addBoldText(dataObj?.name || '', marginLeft + 2, headerY);
    //     doc.setFontSize(10);
    //     headerY += 6;
        item?.tests?.profiling_tests?.forEach(z => {
          const resultVal = z?.result?.current_result || 'Invalid input';
          doc.text(z?.name || '', marginLeft + 2, headerY);
          doc.text(z?.normal_range || '', marginLeft + 50, headerY);
          doc.text(z?.unit || '', marginLeft + 100, headerY);
          if (showPrevResult) {
            doc.text(z?.result?.previous_result || '', marginLeft + 130, headerY);
          }
          const checkWithReference = z?.normal_range?.replaceAll(' ', '')?.split('-');
          if (z?.result?.current_result && checkWithReference?.length > 1) {
            if (Number(checkWithReference[0]) > Number(z?.result?.current_result) || Number(checkWithReference[1]) < Number(z?.result?.current_result)) {
              doc.setFontSize(12);
            }
          }
          addBoldTextRightAlign(resultVal, pageWidth - marginRight, headerY);
          doc.setFontSize(10);
          doc.setTextColor('#121212');
          headerY += 2;
          doc.setDrawColor('#e1dfda');
          doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
          headerY += 4;
        })
      } else {
        const resultVal = item?.tests?.result?.current_result || 'Invalid input';
        doc.text(dataObj?.name || '', marginLeft + 2, headerY);
        doc.text(dataObj?.normal_range || '', marginLeft + 50, headerY);
        doc.text(dataObj?.unit || '', marginLeft + 100, headerY);
        if (showPrevResult) {
          doc.text(item?.tests?.result?.previous_result || '', marginLeft + 130, headerY);
        }
        const checkWithReference = dataObj?.normal_range?.replaceAll(' ', '')?.split('-');
        if (item?.tests?.result?.current_result && checkWithReference?.length > 1) {
          if (Number(checkWithReference[0]) > Number(item?.tests?.result?.current_result) || Number(checkWithReference[1]) < Number(item?.tests?.result?.current_result)) {
            doc.setTextColor('#de0404');
          }
        }
        addBoldTextRightAlign(resultVal, pageWidth - marginRight, headerY);
        doc.setTextColor('#121212');
        headerY += 2;
        doc.setDrawColor('#e1dfda');
        doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
        headerY += 4;
      }
    // });
    headerY += 2;
    doc.setDrawColor('#121212');
    doc.setFontSize(11);
    addBoldText('Note', marginLeft + 2, headerY);
    doc.setFontSize(10);
    headerY += 6;
    doc.text('Lab values should always be correlated with clinical picture. Normal Range(s) and Unit(s) shown are most recent results', marginLeft + 2, headerY);
    return headerY;
  }


  const addFooter = (yMain) => {
    let headerY = yMain;
    
    if (user?.footer) {
      const footerImage = doc.getImageProperties(user.footer);
      headerY = pageHeight - footerImage.height/10 - 11;
      doc.addImage(user.footer, "PNG", marginLeft, headerY, pageWidth - marginLeft - marginRight, 31);
      headerY += 31;
      doc.setFontSize(10);
    } else {
      headerY = pageHeight - 44;
      addBoldText("VIEW LAB REPORTS ONLINE:", marginLeft, headerY);
      headerY += 5;
      addBoldText("To view report online, scan the above QR code", marginLeft, headerY);
      headerY += 5;
      addBoldText("OR", marginLeft, headerY);
      headerY += 5;
      addBoldText("This report can be verified online at https://hims-frontend.vercel.app/", marginLeft, headerY);
      headerY += 5;
      addBoldText("Test reference number: 34546", marginLeft, headerY);
      addBoldTextRightAlign("Patient Id: 34546", pageWidth - marginRight, headerY);
      headerY += 4;
      doc.setFontSize(10);
      doc.setTextColor('#5e6163')
      addBoldText("Electronically verified receipt, no signature(s) required.", marginLeft + 50, headerY);
      doc.setTextColor('#121212')
      headerY += 2;
      doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
      headerY += 1;
      const addSocialLinksPDF = (linksList) => {
        doc.setFont("helvetica", "bold");
        let leftSpace = marginLeft;
        linksList?.forEach(link => {
          doc.addImage(link.image, "PNG", leftSpace, headerY, 4, 4);
          leftSpace += 5;
          doc.text(link?.text, leftSpace, headerY + 3);
          const checkTextWidth = doc.getTextWidth(link?.text);
          leftSpace += checkTextWidth + 2;
        });
        doc.setFont("helvetica", "normal");
      };
      addSocialLinksPDF([
        { image: logoUrl, text: "220A Garden Block Garden Town Lahore." },
        { image: logoUrl, text: "03214146093" },
        { image: logoUrl, text: "03213404554" },
        { image: logoUrl, text: "hims-frontend.vercel.app" },
      ])
      headerY += 5;
      doc.line(marginLeft, headerY, pageWidth - marginRight, headerY);
    }
    headerY += 5;
    addBoldText(`Printing Time: ${moment()?.format('DD-MMM-YYYY HH:mm A')}`, marginLeft, headerY);
    addBoldTextRightAlign("Generated by: MedixLab", pageWidth - marginRight, headerY);
    return headerY;
  }

  // addFooter();
  records?.forEach((recordObj, recordIndex) => {
    let headerYMain = 16;
    const resHeader = addHeader(headerYMain);
    headerYMain = resHeader;
    const resContent = addContent(headerYMain, recordObj);
    headerYMain = resContent;
    const resFooter = addFooter(headerYMain);
    headerYMain = resFooter;
    if (recordIndex !== records?.length-1) {
      doc.addPage();
    }
  })

  // Download the PDF
  // doc.save("receipt.pdf");
  const pdfOutput = doc.output('blob');
  const pdfUrl = URL.createObjectURL(pdfOutput);
  window.open(pdfUrl, '_blank');
};
