import React, { useEffect, useMemo, useState } from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'
import { Link } from 'react-router-dom'
import { searchnormal, report_icon1, pdficon } from '../imagepath';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/root-store';
import moment from 'moment'
import { Spin } from 'antd';
import { ResultInputBox } from './resultInputBox';
import styled from 'styled-components';
import { generateTestResultsPdf } from '../../utils/functions';
import { DocumentHead } from '../../utils/documentHead';
import { PageLayout } from '../../common-components/pageLayout';

const TestResults = observer(() => {
    const { getUserData, patientsStore: { getPatientsData, getPatientRecord, patientsListLoading, patientRecordLoading, fetchPatientsData, fetchPatientRecord, postTestResult } } = useStore('');
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [searchVal, setSearchVal] = useState('');
    const filteredPatientsList = useMemo(() => {
        let filteredResults = getPatientsData || [];
        if (searchVal) {
            filteredResults = getPatientsData?.filter(item => (item?.first_name?.toLowerCase()?.includes(searchVal?.toLowerCase()) || item?.middle_name?.toLowerCase()?.includes(searchVal?.toLowerCase()) || item?.last_name?.toLowerCase()?.includes(searchVal?.toLowerCase()) || `${item?.first_name || ''} ${item?.middle_name || ''} ${item?.last_name || ''}`.toLowerCase()?.includes(searchVal?.toLowerCase())));
        }
        return filteredResults;
    }, [searchVal, getPatientsData]);
    useEffect(() => {
        fetchPatientsData();
    }, []);
    return (
        <>
        <DocumentHead title={'Test Results'} />
            <Header />
            <Sidebar
                id="menu-item2"
                id1="menu-items2"
                activeClassName='test-results' />
            <>
                <PageLayout>
                        {/* Page Header */}
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="">Patients </Link></li>
                                        <li className="breadcrumb-item"><i className="feather-chevron-right">
                                            <FeatherIcon icon="chevron-right" />
                                        </i></li>
                                        <li className="breadcrumb-item active">Test Results</li>
                                    </ul>
                                    {selectedPatient && <div className=" me-2" style={{cursor: 'pointer'}} onClick={async () => {
                                        generateTestResultsPdf(getPatientRecord, getUserData)
                                    }}>
                                        <img src={pdficon} alt="#" />
                                    </div>}
                                </div>
                            </div>
                        </div>
                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-xl-4 d-flex">
                                <div className="card chat-box-clinic ">
                                    <div className="chat-widgets">
                                        <div className="top-liv-search top-chat-search" style={{ paddingTop: '15px' }}>
                                            <form>
                                                <div className="chat-search">
                                                    <div className="form-group me-2 mb-0">
                                                        <input type="text" className="form-control" placeholder="Search here" value={searchVal} onChange={e => setSearchVal(e?.target?.value)} />
                                                        <Link className="btn"><img src={searchnormal} alt="#" /></Link>
                                                    </div>
                                                    {/* <div className="add-search">
                                                        <Link to="/addpatients"><i className="fa fa-plus" /></Link>
                                                    </div> */}
                                                </div>
                                            </form>
                                        </div>
                                        <ListWrapper>
                                            {patientsListLoading ? <CenteredDiv><Spin size="large" /></CenteredDiv> : filteredPatientsList?.map((item, index) => {
                                                return <div key={index} className={`chat-user-group d-flex align-items-center ${selectedPatient?.id === item?.id ? 'selectedItem' : ''}`} onClick={() => {
                                                    setSelectedPatient(item);
                                                    fetchPatientRecord(item?.id);
                                                }}>
                                                    <div className="img-users call-user">
                                                        <Link to="#"><img src={item?.profile_image} style={{ height: '45px', width: '45px' }} alt="#" /></Link>
                                                        <span className="active-users bg-info" />
                                                    </div>
                                                    <div className="chat-users">
                                                        <div className="user-titles d-flex">
                                                            <h5>{item?.first_name || ''} {item?.middle_name || ''} {item?.last_name || ''}</h5>
                                                            <div className="chat-user-time">
                                                                <p>{item?.created_at ? moment(item?.created_at)?.format('DD MMM YYYY') : ''}</p>
                                                            </div>
                                                        </div>
                                                        <div className="user-text d-flex">
                                                            <p>{item?.mr_no}</p>
                                                            {/* <div className="chat-user-count">
                                                                <span>3</span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </ListWrapper>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                {/* Chat */}
                                <div className="card chat-message-box">
                                    <div className="card-body p-0">
                                        <div className="chat-body">
                                            <ul className="list-unstyled chat-message">
                                                <li className="media d-flex received" style={{ justifyContent: 'space-between' }}>
                                                    <div className='d-flex' style={{ gap: '5px' }}>
                                                        <div className="avatar flex-shrink-0" style={{ height: '36px' }}>
                                                            <AvatarImage src={selectedPatient?.profile_image} alt="User" className="avatar-img rounded-circle" />
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="msg-box">
                                                                <div className="message-sub-box">
                                                                    <h4 style={{ fontSize: '16px', marginBottom: 0 }}>{selectedPatient ? `${selectedPatient?.first_name || ''} ${selectedPatient?.middle_name || ''} ${selectedPatient?.last_name || ''}` : 'Patient name'}</h4>
                                                                    <span style={{ fontSize: '11px' }}>Registered at: {getPatientRecord?.registered_at || ''}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="media-body">
                                                            <div className="msg-box">
                                                                <div className="message-sub-box" style={{ textAlign: 'right' }}>
                                                                    <PatientDetailText>MR #: {getPatientRecord?.mr_no}</PatientDetailText>
                                                                    <PatientDetailText>Contact #: {getPatientRecord?.contact_number}</PatientDetailText>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                            <DetailsWrapper>
                                                {selectedPatient ? <>
                                                    {patientRecordLoading ? <CenteredDiv><Spin size="large" /></CenteredDiv> : <>
                                                        {getPatientRecord?.patient_tests?.length ? getPatientRecord?.patient_tests?.map((item, idx) => {
                                                            return <li className="media d-flex received" key={idx}>
                                                                <div className="media-body flex-grow-1">
                                                                    <div className="msg-box">
                                                                        <div className="message-sub-box">
                                                                            <MainTestRow>
                                                                                <p style={{ borderRadius: '10px', background: 'transparent' }}>{item?.tests?.name}</p>
                                                                                {item?.tests?.test_type === 'Individual' && <ResultInputBox z={item?.tests} patientTestId={item?.patient_test_id} getPatientRecord={getPatientRecord} postTestResult={postTestResult} fetchPatientRecord={() => fetchPatientRecord(selectedPatient?.id, true)} />}
                                                                            </MainTestRow>
                                                                            <ul className="msg-sub-list">
                                                                                {item?.tests?.profiling_tests?.map(z => {
                                                                                    return <SubTestRow key={z?.id}>
                                                                                        <div style={{ display: 'flex', gap: '6px' }}>
                                                                                            <img src={report_icon1} alt="#" style={{ height: '22px' }} className="me-1" />
                                                                                            <div>{z?.name}</div>
                                                                                        </div>
                                                                                        <ResultInputBox z={z} getPatientRecord={getPatientRecord} patientTestId={item?.patient_test_id} postTestResult={postTestResult} fetchPatientRecord={() => fetchPatientRecord(selectedPatient?.id, true)} />
                                                                                    </SubTestRow>
                                                                                })}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        }) : <CenteredDiv className='text-muted'>No Tests available.</CenteredDiv>}
                                                    </>}
                                                </> : <>
                                                    <CenteredDiv className='text-muted'>Select a patient to add test results.</CenteredDiv>
                                                </>}
                                            </DetailsWrapper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </PageLayout>

            </>
        </>
    )
})

export default TestResults


const ListWrapper = styled.div`
    height: calc(100vh - 270px);
    overflow: auto;
`;

const DetailsWrapper = styled.div`
    height: calc(100vh - 255px);
    overflow: auto;
`;

const MainTestRow = styled.div`
    background: #3334481A;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
    margin-bottom: 3px;
`;

const SubTestRow = styled.li`
    max-width: 100% !important;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 3px;
`;

const AvatarImage = styled.img`
    min-height: 36px;
    max-height: 36px;
    max-width: 36px;
    min-width: 36px;
`;

const PatientDetailText = styled.h4`
    font-size: 14px !important;
    margin-bottom: 2px !important;
`;

const CenteredDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
