import React from 'react'
import Sidebar from '../Sidebar'
import Header from '../Header'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'

const Localization = () => {
  return (
    <div>
        <Header/>
        <Sidebar />
        <div className="page-wrapper">
  {/* Page Content */}
  <div className="content container-fluid">
    {/* Page Header */}
    <div className="page-header">
      <div className="row">
        <div className="col-sm-12">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="index.html">Dashboard </Link>
            </li>
            <li className="breadcrumb-item">
              <i className="feather-chevron-right">
                <FeatherIcon icon="chevron-right"/>
                </i>
            </li>
            <li className="breadcrumb-item active">Settings</li>
          </ul>
        </div>
      </div>
    </div>
    {/* /Page Header */}
    <div className="settings-menu-links">
      <ul className="nav nav-tabs menu-tabs">
        <li className="nav-item ">
          <Link className="nav-link" to="/settings">
            General Settings
          </Link>
        </li>
        <li className="nav-item active">
          <Link className="nav-link" to="/localization">
            Localization
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/paymentsetting">
            Payment Settings
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/settingsemail">
            Email Settings
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/settingssocialmedia">
            Social Media Login
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/settingssociallink">
            Social Links
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/settingsseo">
            SEO Settings
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/settingsthem">
            Theme Settings
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/settingschangepassword">
            Change Password
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/settingsothers">
            Others
          </Link>
        </li>
      </ul>
    </div>
    <div className="row">
      <div className="col-md-6">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">Localization Details</h5>
          </div>
          <div className="card-body pt-0">
            <form>
              <div className="settings-form">
                <div className="form-group">
                  <label>Time Zone</label>
                  <select className="select form-control">
                    <option selected="selected">
                      (UTC +5:30) Antarctica/Palmer
                    </option>
                    <option>(UTC+05:30) India</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Date Format</label>
                  <select className="select form-control">
                    <option selected="selected">15 May 2016</option>
                    <option>15/05/2016</option>
                    <option>15.05.2016</option>
                    <option>15-05-2016</option>
                    <option>05/15/2016</option>
                    <option>2016/05/15</option>
                    <option>2016-05-15</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Time Format</label>
                  <select className="select form-control">
                    <option selected="selected">12 Hours</option>
                    <option>24 Hours</option>
                    <option>36 Hours</option>
                    <option>48 Hours</option>
                    <option>60 Hours</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Currency Symbol</label>
                  <select className="select form-control">
                    <option selected="selected">$</option>
                    <option>₹</option>
                    <option>£</option>
                    <option>€</option>
                  </select>
                </div>
                <div className="form-group mb-0">
                  <div className="settings-btns">
                    <button
                      type="submit"
                      className="border-0 btn btn-primary btn-gradient-primary btn-rounded"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      
    </div>
  )
}

export default Localization;
