import { toJS } from 'mobx';
import { flow, types } from 'mobx-state-tree';
import api from '../api';


const patientRecordModel = types.model({ 
  age: types.maybeNull(types.string),
  barcode_image: types.maybeNull(types.string),
  blood_group: types.maybeNull(types.string),
  city: types.maybeNull(types.number),
  company: types.maybeNull(types.number),
  contact_number: types.maybeNull(types.string),
  country: types.maybeNull(types.number),
  created_at: types.maybeNull(types.string),
  date_of_birth: types.maybeNull(types.string),
  mr_no: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  first_name: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  is_active: types.maybeNull(types.boolean),
  last_name: types.maybeNull(types.string),
  middle_name: types.maybeNull(types.string),
  nic: types.maybeNull(types.string),
  panel: types.maybeNull(types.string),
  passport_number: types.maybeNull(types.string),
  profile_image: types.maybeNull(types.string),
  qrcode_image: types.maybeNull(types.string),
  referred_by: types.maybeNull(types.union(types.string, types.number)),
  registered_by: types.maybeNull(types.number),
  state: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
});

// old patient record model
// const patientSingleRecordModel = types.model({
//   age: types.maybeNull(types.string),
//   barcode_image: types.maybeNull(types.string),
//   blood_group: types.maybeNull(types.string),
//   city: types.maybeNull(types.string),
//   company: types.maybeNull(types.number),
//   contact_number: types.maybeNull(types.string),
//   country: types.maybeNull(types.string),
//   created_at: types.maybeNull(types.string),
//   date_of_birth: types.maybeNull(types.string),
//   email: types.maybeNull(types.string),
//   first_name: types.maybeNull(types.string),
//   gender: types.maybeNull(types.string),
//   id: types.maybeNull(types.number),
//   is_active: types.maybeNull(types.boolean),
//   last_name: types.maybeNull(types.string),
//   middle_name: types.maybeNull(types.string),
//   mr_no: types.maybeNull(types.string),
//   nic: types.maybeNull(types.string),
//   panel: types.maybeNull(types.string),
//   passport_number: types.maybeNull(types.string),
//   profile_image: types.maybeNull(types.string),
//   qrcode_image: types.maybeNull(types.string),
//   referred_by: types.maybeNull(types.string),
//   registered_at: types.maybeNull(types.string),
//   registered_by: types.maybeNull(types.string),
//   state: types.maybeNull(types.string),
//   test_detail: types.maybeNull(types.model({
//   created_at: types.maybeNull(types.string),
//   discount: types.maybeNull(types.number),
//   due_amount: types.maybeNull(types.number),
//   id: types.maybeNull(types.number),
//   is_active: types.maybeNull(types.boolean),
//   lab_no: types.maybeNull(types.number),
//   paid_amount: types.maybeNull(types.number),
//   patient: types.maybeNull(types.number),
//   // sample_required
//   // : 
//   // null
//   // test_details: types.maybeNull(types.string),
//   test_status: types.maybeNull(types.string),
//   tests: types.maybeNull(types.array(types.maybeNull(types.model({
//         created_at: types.maybeNull(types.string),
//         created_by: types.maybeNull(types.number),
//         description: types.maybeNull(types.string),
//         id: types.maybeNull(types.number),
//         is_active: types.maybeNull(types.boolean),
//         name: types.maybeNull(types.string),
//         price: types.maybeNull(types.string),
//         report_time: types.maybeNull(types.string),
//         reporting_time: types.maybeNull(types.string),
//         updated_at: types.maybeNull(types.string),
//       })))),
//     total_amount: types.maybeNull(types.number),
//     updated_at: types.maybeNull(types.string),
//   })), 
//   title: types.maybeNull(types.string),
//   updated_at: types.maybeNull(types.string),
// });

const DepartmentModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
})

const IndividualTestModel = types.model({
  id: types.maybeNull(types.number),
  result: types.maybeNull(types.model({
    "previous_result": types.maybeNull(types.union(types.string, types.number)),
    "previous_date": types.maybeNull(types.string),
    "current_result": types.maybeNull(types.union(types.string, types.number)),
    "current_date": types.maybeNull(types.string),
  })),
  department: types.maybeNull(DepartmentModel),
  created_at: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  is_active: types.maybeNull(types.boolean),
  test_type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  price: types.maybeNull(types.string),
  report_time: types.maybeNull(types.string),
  normal_range: types.maybeNull(types.string),
  unit: types.maybeNull(types.string),
  sample_required: types.maybeNull(types.string),
  created_by: types.maybeNull(types.number),
});

const UpdatedTestModel = types.model({
  name: types.maybeNull(types.string),
  price: types.maybeNull(types.union(types.number, types.string)),
  report_time: types.maybeNull(types.string),
  normal_range: types.maybeNull(types.string),
  unit: types.maybeNull(types.string),
  sample_required: types.maybeNull(types.string),
})

// Define profiling test model
const ProfilingTestModel = types.model({
  id: types.maybeNull(types.number),
  result: types.maybeNull(types.model({
    "previous_result": types.maybeNull(types.union(types.string, types.number)),
    "previous_date": types.maybeNull(types.string),
    "current_result": types.maybeNull(types.union(types.string, types.number)),
    "current_date": types.maybeNull(types.string),
  })),
  department: types.maybeNull(DepartmentModel),
  created_at: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  is_active: types.maybeNull(types.boolean),
  test_type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  price: types.maybeNull(types.string),
  report_time: types.maybeNull(types.string),
  reporting_time: types.maybeNull(types.string),
  normal_range: types.maybeNull(types.string),
  unit: types.maybeNull(types.string),
  sample_required: types.maybeNull(types.string),
  created_by: types.maybeNull(types.number),
  profiling_tests: types.array(IndividualTestModel),
  source: types.maybeNull(types.string),
  updated_test: types.maybeNull(UpdatedTestModel),
});

// Define patient test model
const PatientTestModel = types.model({
  patient_test_id: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  tests: types.maybeNull(ProfilingTestModel),
});

// Define test detail model
const TestDetailModel = types.model({
  test_detail_id: types.maybeNull(types.number),
  total_amount: types.maybeNull(types.number),
  discount: types.maybeNull(types.number),
  paid_amount: types.maybeNull(types.number),
  sample_required: types.maybeNull(types.string),
  lab_no: types.maybeNull(types.number),
});

// Define patient model
const patientSingleRecordModel = types.model({
  barcode_image: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  qrcode_image: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  age: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  blood_group: types.maybeNull(types.string),
  company: types.maybeNull(types.number),
  contact_number: types.maybeNull(types.string),
  date_of_birth: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  first_name: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  last_name: types.maybeNull(types.string),
  middle_name: types.maybeNull(types.string),
  mr_no: types.maybeNull(types.string),
  nic: types.maybeNull(types.string),
  panel: types.maybeNull(types.number),
  passport_number: types.maybeNull(types.string),
  patient_id: types.maybeNull(types.number),
  profile_image: types.maybeNull(types.string),
  referred_by: types.maybeNull(types.number),
  registered_at: types.maybeNull(types.string),
  registered_by: types.maybeNull(types.union(types.string, types.number)),
  test_detail: types.maybeNull(TestDetailModel),
  patient_tests: types.array(PatientTestModel),
});

export const PatientsStore = types.model({
    pageNumber: types.number,
    pageSize: types.number,
    totalCount: types.maybeNull(types.number),
    patientsListLoading: types.boolean,
    patientStatsLoading: types.boolean,
    patientRecordLoading: types.boolean,
    updatingRecord: types.boolean,
    creatingRecord: types.boolean,
    patientsList: types.array(patientRecordModel),
    patientRecord: types.maybeNull(patientSingleRecordModel),
    patientStats: types.maybeNull(types.model({
      discount: types.maybeNull(types.number),
      due_amount: types.maybeNull(types.number),
      paid_amount: types.maybeNull(types.number),
      patient_count: types.maybeNull(types.number),
      total_amount: types.maybeNull(types.number),
    }))
}).views(self => ({
    get getPatientsData() {
      return toJS(self.patientsList);
    },
    get getPatientRecord() {
      return toJS(self.patientRecord);
    },
  })).actions(self => {
  const fetchPatientsData = flow(function* (query='') {
    self.patientsListLoading = true;
    try {
      const response = yield api.get(`/labs/patient_listing/?query=${query}&page=${self.pageNumber}&per_page=${self.pageSize}`);
      self.patientsList = response?.data?.data;
      self.totalCount = response?.data?.total_count;
      self.patientsListLoading = false;
    } catch (e) {
      self.patientsListLoading = false;
      if (e?.response?.data?.detail === 'Invalid page.' && self.pageNumber !== 1) {
        self.pageNumber = self.page - 1;
        fetchPatientsData();
      }
      return Promise.reject(e);
    }
  });
  const fetchPatientRecord = flow(function* (id, noLoading=false) {
    if (!noLoading) {
      self.patientRecordLoading = true;
      self.patientRecord = null;
    }
    try {
      const response = yield api.get(`/labs/get_patient/${id}/`);
      self.patientRecord = response?.data?.data;
      self.patientRecordLoading = false;
      return response?.data;
    } catch (e) {
      self.patientRecordLoading = false;
      return Promise.reject(e);
    }
  });
  const resetPatientRecord = () => {
    self.patientRecord = null;
  }
  const fetchOldPatientRecord = flow(function* (id, noLoading=false) {
    if (!noLoading) {
      self.patientRecordLoading = true;
      self.patientRecord = null;
    }
    try {
      const response = yield api.get(`/labs/patient_detail/${id}/`);
      self.patientRecord = response?.data?.data;
      self.patientRecordLoading = false;
      return response?.data;
    } catch (e) {
      self.patientRecordLoading = false;
      return Promise.reject(e);
    }
  });
  const createPatientRecord = flow(function* (payload) {
    self.creatingRecord = true;
    try {
      const response = yield api.post(`/labs/add_patient/`, payload);
      self.creatingRecord = false;
      return response?.data;
    } catch (e) {
      self.creatingRecord = false;
      return Promise.reject(e);
    }
  });
  const addOldPatientRecord = flow(function* (payload) {
    self.updatingRecord = true;
    try {
      const response = yield api.post(`/labs/add_old_patient/`, payload);
      self.updatingRecord = false;
      return response?.data;
    } catch (e) {
      self.updatingRecord = false;
      return Promise.reject(e);
    }
  });
  const updatePatientRecord = flow(function* (id, payload) {
    self.updatingRecord = true;
    try {
      const response = yield api.put(`/labs/update_patient/${id}/`, payload);
      self.updatingRecord = false;
      return response?.data;
    } catch (e) {
      self.updatingRecord = false;
      return Promise.reject(e);
    }
  });
  const postTestResult = flow(function* (payload) {
    try {
      const response = yield api.post(`/labs/add_result/`, payload);
      return response?.data;
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const fetchPatientStats = flow(function* (params) {
    self.patientStatsLoading = true;
    try {
      const response = yield api.get(`/labs/patient_stats/`, {
        params
      });
      self.patientStats = response?.data?.data;
      self.patientStatsLoading = false;
    } catch (e) {
      self.patientStatsLoading = false;
      return Promise.reject(e);
    }
  });
  const deletePatient = flow(function* (id) {
    try {
      const response = yield api.delete(`/labs/delete_patient/${id}/`);
      return response?.data;
    } catch (e) {
      return Promise.reject(e);
    }
  });
  const handlePagination = (page, pageSize) => {
    self.pageNumber = page;
    self.pageSize = pageSize;
    fetchPatientsData();
  };
  const resetPagination = () => {
    self.pageNumber = 1;
    self.pageSize = 10;
  };
  return {
    fetchPatientsData,
    createPatientRecord,
    fetchPatientRecord,
    updatePatientRecord,
    postTestResult,
    fetchPatientStats,
    fetchOldPatientRecord,
    addOldPatientRecord,
    resetPatientRecord,
    deletePatient,
    handlePagination,
    resetPagination,
  };
});

export function initPatientsStore() {
    return PatientsStore.create({
        patientsList: [],
        patientsListLoading: true,
        patientStatsLoading: false,
        patientRecordLoading: false,
        patientRecord: null,
        creatingRecord: false,
        updatingRecord: false,
        pageNumber: 1,
        pageSize: 10,
    });
}
